import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';

// Dependencies
// import TestMarginHeader from './test_text'
import ButtonDownloadExcel from './../Layout/buttons/ButtonDownloadExcel';
import ButtonDownloadPDF from './../Layout/buttons/ButtonDownloadPDF';

import Map from './Map';

const Header = (props) => {
    const wholeData = props.wholeData;

    const [bool_Show, setBool] = useState(1);
    const [FilterOffset, setFilterOffset] = useState(props.offset);

    const [locationsObject, setLocationsObject] = useState(null);
    const [wholeDataFiltered, setWholeDataFiltered] = useState(null);
    const [dataObj, setDataObj] = useState(null);


    if (wholeData && wholeDataFiltered == null) {
        setWholeDataFiltered(wholeData);
    }

    const dt_k = JSON.parse(props.dt_k);
    const d_k_sub = JSON.parse(props.d_k_sub);


    const Header = styled.div ` 
        background-color: #2946D7;

        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
        list-style: none;
        line-height: 20px;

        padding: 3px 10px 3px 10px;
        border-radius: 0pt;
        border-color: #000000;
        border-width: 0pt;
        border-style: solid;
        top: 50%;

        position: sticky;
        margin: 0 auto;
        vertical-align: middle;
        top: 96px;

        z-index: 99;
    `

    const GraphZone = styled.div ` 
        width: 100%;
    `

    const Section = styled.div ` 
        margin-bottom: 20pt;
    `


    const MarginHeader = styled.div ` 
        margin-top: 3.7pt;
        color: white;
    `

    const Button = styled.div ` 
        background-color:#ffffff;
    `

    const MapContainer = styled.div ` 
        margin-top: 0pt;
        margin-bottom: 0pt;
    `

    const ToggleContent = () => {
        if (bool_Show) {
            setBool(0);
        } else {
            setBool(1);
        }
    }

    const offset_desired = React.useRef();

    const clickFilter = () => {
        var offset_filter_aux = parseInt(offset_desired.current.value);
        if (offset_filter_aux !== FilterOffset) {
            setFilterOffset(offset_filter_aux);
        }
    }


    useEffect(()=>{   
        var now = new Date();
        var utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() , 
          now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        var current_utc = utc_timestamp/1000;
        var threshold_utc = current_utc - FilterOffset;


        var colors_vector = ['0062ff', 'ff9100', 'a2ff00', 'ff0000', '33ffbd', '6c3f00', '581845', 'ff33da', '006c04', '8f9000', '90004a'];
        var l = 0;
        var k = 0; // points at the position to be evaluated of an array. If array is spliced, keeps the position, if not, increments by 1
        var id = 0;
        var dataObj_copy = JSON.parse(JSON.stringify(dataObj));
        var locations = [];
        var locations_aux = [];
        if (dataObj && dataObj_copy) {
            for (var j in dt_k) { // iterate through device types
                for (var i in d_k_sub[j]) { // iterate through devices in each device type

                        let found = false;
                        var found_value = null;

                        if (FilterOffset == -1) { // we've been asked to show the last position
                            for (let h = dataObj_copy[dt_k[j]][i][d_k_sub[j][i]].length - 1; h >= 0; h--) { // iterate through each row of the given device, backwards
                                if (dataObj[dt_k[j]][i][d_k_sub[j][i]][h]['GPS_longitude'] && !found) { // when device found, set found flag to true, no need to read more values
                                    found = true;
                                    found_value = dataObj[dt_k[j]][i][d_k_sub[j][i]][h];
                                }
                            }
                        }
                        else{ // normal operation, we filter by time
                            for (var h = 0 ; h < dataObj_copy[dt_k[j]][i][d_k_sub[j][i]].length; h++) { // iterate through each row of the given device
                                var timeUnix = dataObj[dt_k[j]][i][d_k_sub[j][i]][k].time_unix;
                                var time_readable = dataObj[dt_k[j]][i][d_k_sub[j][i]][k].time;
                                var GPS_latitude = (dataObj[dt_k[j]][i][d_k_sub[j][i]][k].GPS_latitude);
                                var GPS_longitude = (dataObj[dt_k[j]][i][d_k_sub[j][i]][k].GPS_longitude);

                                if (timeUnix < threshold_utc || !GPS_latitude || !GPS_longitude) { 
                                    dataObj[dt_k[j]][i][d_k_sub[j][i]].splice(k,1);
                                } else {
                                    k++;
                                    if (GPS_latitude && GPS_longitude) {
                                        if (l < colors_vector.length) { // if possible, set the color to one of the array
                                            locations_aux.push({'GPS_latitude': parseFloat(GPS_latitude), 'GPS_longitude': parseFloat(GPS_longitude), 'time': time_readable, 'id': id, 'name': d_k_sub[j][i][0], 'color': colors_vector[l]})
                                        } else { // if not, set the color to the first one
                                            locations_aux.push({'GPS_latitude': parseFloat(GPS_latitude), 'GPS_longitude': parseFloat(GPS_longitude), 'time': time_readable, 'id': id, 'name': d_k_sub[j][i][0], 'color': colors_vector[0]})
                                        }
                                        id++;
                                    }
                                }
                            }
                        }

                    if (found) { // if found value, it means the filter offset is -1. Create a json item as previously, but now the id is set to 0
                        var timeUnix = found_value.time_unix;
                        var time_readable = found_value.time;
                        var GPS_latitude = found_value.GPS_latitude;
                        var GPS_longitude = found_value.GPS_longitude;
                        if (l < colors_vector.length) {
                            locations_aux.push({'GPS_latitude': parseFloat(GPS_latitude), 'GPS_longitude': parseFloat(GPS_longitude), 'time': time_readable, 'id': 0, 'name': d_k_sub[j][i][0], 'color': colors_vector[l]})
                        } else {
                            locations_aux.push({'GPS_latitude': parseFloat(GPS_latitude), 'GPS_longitude': parseFloat(GPS_longitude), 'time': time_readable, 'id': 0, 'name': d_k_sub[j][i][0], 'color': colors_vector[0]})
                        }
                    }


                    if (locations_aux.length > 0) { // Some GPS data is present
                        locations.push(locations_aux);
                        locations_aux = [];
                        l++;
                    }

                    id=0;
                    k=0;
                }
            }

            setLocationsObject(JSON.stringify(locations));
            setWholeDataFiltered(JSON.stringify(dataObj));

        }
    }, [dataObj]); 


    useEffect(()=>{   
        if (wholeData) {
            setDataObj(JSON.parse(wholeData));
        }
    }, [FilterOffset]); 

 
    return ( 
        <Section>
            <Header>
                <li> 
                <MarginHeader>
                    {props.variable}
                    <Button onClick={ToggleContent} className="generalbutton" > Toggle  </Button>
                </MarginHeader>
                </li>

                {bool_Show ? (
                    <>
                        <li>
                        <MarginHeader>
                            Temporal filter
                            <select ref={offset_desired} defaultValue={FilterOffset} className="generalbutton" >
                                <option value="31556926"> ALL </option>
                                <option value="-1"> LAST </option>
                                <option value="86400"> 24 hours </option>
                                <option value="172800"> 48 hours </option>
                                <option value="604800"> 7 days </option>
                                <option value="2592000"> 1 month </option>
                                <option value="31556926"> 1 year </option>
                            </select>
                            <Button onClick={clickFilter} className="generalbutton">Apply</Button>
                        </MarginHeader>
                        </li>

                        <li>
                            <ButtonDownloadPDF variable={props.variable}/>
                            <ButtonDownloadExcel variable={props.variable} wholeData={wholeDataFiltered}  dt_k={JSON.stringify(dt_k)} d_k_sub={JSON.stringify(d_k_sub)} />
                        </li>
                    </>
                ) : (null)}
                
            </Header>

            <GraphZone id={props.variable}>
                {bool_Show ? ( 
                        <MapContainer>
                            {dt_k && d_k_sub ? (
                                <Map locations={locationsObject} dC={props.dC} />
                            ) : (null)}
                        </MapContainer>
                ) : (null)}
            </GraphZone>

        </Section>
    );

}
 
export default Header;
