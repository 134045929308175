
import React, { useEffect, useState } from 'react';
import { get_docClient } from './../../AWS-SDK/AWS';
import { AccountContext } from '../UserAuthentication/Accounts';
const UsersDevicesTable = () => {
  const [usersDevicesData, setUsersDevicesData] = useState([]);
  const [email, setEmail] = useState('');
  const [devices, setDevices] = useState('');
  // per editar els dispositius d'un usuari 
  const [editingUser, setEditingUser] = useState(null);
  const [newDevices, setNewDevices] = useState('');

  useEffect(() => {
    // càrrega inicial de les dades de la taula desde dynamodb
    fetchUsersDevicesData();
  }, []);

  const fetchUsersDevicesData = async () => {
    try {
      const docClient = get_docClient();
      const params = {
        TableName: 'Users_and_DeviceTypes',
      }; // escanejar la taula i obtenir-ne els elements
      const data = await docClient.scan(params).promise();
      if (data && data.Items) {
        // guardem les dades en la variable d'estat
        setUsersDevicesData(data.Items);
      }
    } catch (error) {
      console.error('Error fetching data from DynamoDB:', error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault(); // perque la pagina no fagi reload
    
    try {
      const docClient = get_docClient();
      const params = {
        TableName: 'Users_and_DeviceTypes', 
        Item: {
          Users: email,
          DeviceTypes: devices,
        },
      };

      await docClient.put(params).promise();
 
      setUsersDevicesData([...usersDevicesData, { Users: email, DeviceTypes: devices }]);

      // Neteja dels camps un cop s'han entrat els valors
      setEmail('');
      setDevices('');
    } catch (error) {
      console.error('Error saving data to DynamoDB:', error);
    }
  };
// funció per a editar els dispositius d'un usuari
  const handleEditUserDevices = (user) => {
    setEditingUser(user);
    setNewDevices(user.DeviceTypes);
  };

// afegir un usuari + dispositius
  const handleSaveDevices = async () => {
    try {
      const docClient = get_docClient();
      const params = {
        TableName: 'Users_and_DeviceTypes', 
        Key: {
          Users: editingUser.Users,
        },
        UpdateExpression: 'SET DeviceTypes = :devices',
        ExpressionAttributeValues: {
          ':devices': newDevices,
        },
      };

      await docClient.update(params).promise();
      // actualitzem la variable d'estat amb el nou usuari
      setUsersDevicesData((prevData) =>
        prevData.map((item) => (item.Users === editingUser.Users ? { ...item, DeviceTypes: newDevices } : item))
      );

     
      setEditingUser(null);
    } catch (error) {
      console.error('Error saving data to DynamoDB:', error);
    }
  };

  return (
    <div>
      <h2>Users and Their Devices</h2>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Devices</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {usersDevicesData.map((item) => (
            <tr key={item.Users}>
              <td>{item.Users}</td>
              <td>{item.DeviceTypes}</td>
              <td>
                <button onClick={() => handleEditUserDevices(item)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingUser && (
        <div style={{ display: 'block', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
          <div style={{ backgroundColor: '#fff', padding: '20px', width: '500px', margin: '100px auto' }}>
            <h3>Edit Devices for User: {editingUser.Users}</h3>
            <input type="text" value={newDevices} onChange={(e) => setNewDevices(e.target.value)} />
            <button onClick={handleSaveDevices}>Save</button>
            <button onClick={() => setEditingUser(null)}>Cancel</button>
          </div>
        </div>
      )}

      <form onSubmit={handleFormSubmit}>
        <div>
          <label>Email:</label>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          <label>Devices:</label>
          <input type="text" value={devices} onChange={(e) => setDevices(e.target.value)} />
        </div>
        <button type="submit">Add User</button>
      </form>
    </div>
  );
};

export default UsersDevicesTable;