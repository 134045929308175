import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';

// Dependencies
import ChartSummoner from '../TemperatureGraph/chartSummoner';
import ButtonDownloadExcel from './buttons/ButtonDownloadExcel';
import ButtonDownloadPDF from './buttons/ButtonDownloadPDF';
import DataBlocks from './data_blocks'



const Header = (props) => {
    const wholeData = props.wholeData;

    const [bool_Show, setBool] = useState(0);
    const [FilterOffset, setFilterOffset] = useState(props.offset);

    const [wholeDataFiltered, setWholeDataFiltered] = useState(null);
    const [dataObj, setDataObj] = useState(null);

    if (wholeData && wholeDataFiltered == null) {
        // setWholeDataFiltered(wholeData);
    }

    const dt_k = JSON.parse(props.dt_k);
    const d_k_sub = JSON.parse(props.d_k_sub);


    const Header = styled.div ` 
        background-color: #2946D7;

        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
        list-style: none;
        line-height: 20px;

        padding: 3px 10px 3px 10px;
        border-radius: 0pt;
        border-color: #000000;
        border-width: 0pt;
        border-style: solid;
        top: 50%;

        position: sticky;
        margin: 0 auto;
        vertical-align: middle;
        top: 96px;

        z-index: 99;
    `

    const GraphZone = styled.div ` 
        /* background-color: #e8e8e8, */
        width: 100%;
        /* line-height: 10pt; */
    `

    const Section = styled.div ` 
        /* background-color: #e8e8e8, */
        margin-bottom: 20pt;
    `


    const MarginHeader = styled.div ` 
        margin-top: 3.7pt;
        color: white;
    `

    const Button = styled.div ` 
        background-color:#ffffff;
    `

    const ToggleContent = () => {
        if (bool_Show) {
            setBool(0);
        } else {
            setBool(1);
        }
    }

    const offset_desired = React.useRef();

    const clickFilter = () => {
        var offset_filter_aux = parseInt(offset_desired.current.value);
        if (offset_filter_aux !== FilterOffset) {
            setFilterOffset(offset_filter_aux);
        }
    }


    useEffect(()=>{   
        if (dataObj) {
            var now = new Date();
            var utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() , 
              now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
            var current_utc = utc_timestamp/1000;
            if (FilterOffset != -1) {
                var threshold_utc = current_utc - FilterOffset;
            }

            var k = 0; // points at the position to be evaluated of an array. If array is spliced, keeps the position, if not, increments by 1
            var dataObj_copy = JSON.parse(JSON.stringify(dataObj));
            if (dataObj && dataObj_copy) {
                for (let j in dt_k) { // iterate through device types
                    for (let i in d_k_sub[j]) { // iterate through devices in each device type
                        if (FilterOffset == -1) {
                            let found = false;
                            var found_value = null;
                            for (let h = dataObj_copy[dt_k[j]][i][d_k_sub[j][i]].length - 1; h >= 0; h--) { // iterate through each row of the given device, backwards
                                if (dataObj[dt_k[j]][i][d_k_sub[j][i]][h][props.variable.toLowerCase()] && !found) { // if the variable is present and its time is the greatest, then store it
                                    found = true;
                                    found_value = dataObj[dt_k[j]][i][d_k_sub[j][i]][h];
                                }
                            }
                            dataObj[dt_k[j]][i][d_k_sub[j][i]] = []; // clear all the previous information
                            if (found) {
                                dataObj[dt_k[j]][i][d_k_sub[j][i]][0] = found_value; // if found, store only the found value
                            }
                        }
                        else { // normal operation, filter by time
                             var k=0;
                             for (var h = 0 ; h < dataObj_copy[dt_k[j]][i][d_k_sub[j][i]].length; h++) { // iterate through each row of the given device
                                var timeUnix = dataObj[dt_k[j]][i][d_k_sub[j][i]][k].time_unix;
                                if (timeUnix < threshold_utc) { 
                                    dataObj[dt_k[j]][i][d_k_sub[j][i]].splice(k,1);
                                } else {
                                    k++;
                                }
                            }
                            k=0;
                        }
                    }
                }
                setWholeDataFiltered(JSON.stringify(dataObj));
                setBool(1);
            }
        }
    }, [dataObj]); 


    useEffect(()=>{   
        if (wholeData) {
            var ordenat = JSON.parse(wholeData);
            setDataObj(ordenat);
        }
    }, [FilterOffset]); 

 
    return ( 
        <Section>
            <Header>
                <li> 
                <MarginHeader>
                    {props.variable}
                    <Button onClick={ToggleContent} className="generalbutton" > Toggle  </Button>
                </MarginHeader>
                </li>

                {bool_Show ? (
                    <>
                        <li>
                        <MarginHeader>
                            Temporal filter
                            <select ref={offset_desired} defaultValue={FilterOffset} className="generalbutton" >
                                <option value="31556926"> ALL </option>
                                <option value="-1"> LAST </option>
                                <option value="86400"> 24 hours </option>
                                <option value="172800"> 48 hours </option>
                                <option value="604800"> 7 days </option>
                                <option value="2592000"> 1 month </option>
                                <option value="31556926"> 1 year </option>
                            </select>
                            <Button onClick={clickFilter} className="generalbutton">Apply</Button>
                        </MarginHeader>
                        </li>

                        <li>
                            <ButtonDownloadPDF variable={props.variable} />
                            <ButtonDownloadExcel variable={props.variable} wholeData={wholeDataFiltered}  dt_k={JSON.stringify(dt_k)} d_k_sub={JSON.stringify(d_k_sub)} />
                        </li>
                    </>
                ) : (null)}
                
            </Header>

            {/* <GraphZone id={props.variable}> */}
                {bool_Show && wholeDataFiltered ? ( 
                    <GraphZone id={props.variable}>
                        <DataBlocks  variable={props.variable.toLowerCase()}  wholeData={wholeDataFiltered} dt_k={dt_k} d_k_sub={d_k_sub}  />
                        <ChartSummoner variable={props.variable}  wholeData={wholeDataFiltered} dt_k={dt_k} d_k_sub={d_k_sub}  /> 
                    </GraphZone>
                ) : (null)}
            {/* </GraphZone> */}

        </Section>
    );

}
 
export default Header;
