import React, { useState, useEffect } from 'react';
import { get_docClient } from '../../AWS-SDK/AWS';
import { Line } from 'react-chartjs-2';
import "./gesab.css";


const UsersDevicesTable = () => {

  const [consoles, setConsoles] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedData, setSelectedData] = useState([]); // Store data for selected devices

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const timeFilterOptions = [
    { value: 1, label: 'Last 24 hours' },
    { value: 7, label: 'Last Week' },
    { value: 30, label: 'Last Month' },
    { value: 60, label: 'Last 2 Months' },
    { value: 180, label: 'Last 6 Months' },
    { value: 365, label: 'Last Year' },
   // { value: null, label: 'All'}
  ];
  useEffect(() => {
    fetchConsoles(); // Fetch inhaler names if needed
  }, []);
  

  const fetchConsoles = async () => {

    try {

      const docClient = get_docClient();
      const params = {
        TableName: 'Gesab_Devices',
      };

      const data = await docClient.scan(params).promise();
      // const consoleNames = data.Items.map(item => item.Device_Name);
      const consoleNames = data.Items
      setConsoles(consoleNames);
      console.log('CONSOLE NAMES', consoleNames);
    } catch (error) {
      console.error('Error fetching console names', error);
    }

  };

  const handleDateFilter = async () => {
    console.log('Start Date Filter:', startDateFilter);
    console.log('End Date Filter:', endDateFilter);

    const filteredData =[];
   
    const startUnixTime = new Date(startDateFilter).getTime() / 1000;
    const endUnixTime = (new Date(endDateFilter).getTime() / 1000) + 86400; // Adding 24 hours to include the end day
  
    console.log('startUnixTime', startUnixTime);
    console.log('endUnixTime', endUnixTime);
         
    for (const deviceName of selectedDevices) {
      const tableData = await fetchTableData(deviceName, startUnixTime, endUnixTime);
      const deviceData = tableData.map(item => ({
        ...item,
        device: deviceName,
      }));
      filteredData.push(...deviceData);
    }
    
    setSelectedData(filteredData);
    console.log('selectedData after filtering', selectedData);
  }; 
  
  const handleConsoleCheckboxChange = (event) => {

    const consoleName = event.target.value;
    if (event.target.checked) {
      setSelectedDevices(prevSelected => [...prevSelected, consoleName]);
    } else {
      setSelectedDevices(prevSelected => prevSelected.filter(name => name !== consoleName));

    }

  };

  const processTimestamp = (Timestamp) => {

    const [date, time] = Timestamp.split(' ');
    const [day, month, year] = date.split('/');
    const [hour, minute, second] = time.split(':');
    const utcTimestamp = Date.UTC(year, month - 1, day, hour, minute, second);

    return new Date(utcTimestamp).toLocaleString();

  };

  
  const handleDisplaySelected = async () => {
    const mergedData = [];
    console.log('device names:', selectedDevices);
    for (const deviceName of selectedDevices) {
      const tableData = await fetchTableData(deviceName, null, null);
      const deviceData = tableData.map(item => ({
        ...item,
        device: deviceName,
      }));
      mergedData.push(...deviceData);
    }
    // Update the selectedData state with the merged data
    setSelectedData(mergedData); // Use await here
  };
  
  
 const handleTimeFilter = async (numDays) => {
    const currentDate = new Date();
    const endDate = currentDate.toISOString().split(' ')[0];
    
    const subtractedDate = new Date();
    subtractedDate.setDate(currentDate.getDate() - numDays);
    const startDate = subtractedDate.toISOString().split(' ')[0];
  
    setStartDate(startDate);
    setEndDate(endDate);
  
    const startUnixTime = new Date(startDate).getTime() / 1000;
    const endUnixTime = (new Date(endDate).getTime() / 1000) + 86400; // Adding 24 hours to include the end day

    console.log('startUnixTime', startUnixTime);
    console.log('endUnixTime', endUnixTime);
    const filteredData = [];    
    for (const deviceName of selectedDevices) {
      const tableData = await fetchTableData(deviceName, startUnixTime, endUnixTime);
      const deviceData = tableData.map(item => ({
        ...item,
        device: deviceName,
      }));
      filteredData.push(...deviceData);
    }
    setSelectedData(filteredData);
    console.log('selectedData after filtering', selectedData);
  }; 
  
  const fetchTableData = async (deviceName, startUnixTime, endUnixTime) => {
    console.log('deviceName: ', deviceName);
    try {
      const docClient = get_docClient();
      const params = {
        TableName: deviceName,
      };
      const data = await docClient.scan(params).promise();
      console.log('data: ', data.Items);
      // Sort the filtered data by Unix_Time in ascending order
     
      if (startUnixTime !== null && endUnixTime !== null) {
          // Filter the combined data for all selected devices
        const filteredData = data.Items.filter(item => {
        const unixTime = item.Unix_Time; // Replace with the actual attribute name in your table
        return (!startUnixTime || unixTime >= startUnixTime) &&
                   (!endUnixTime || unixTime <= endUnixTime);
      });
  
        return filteredData;
      } else {
        // If startUnixTime and endUnixTime are not provided, return all data
        return data.Items;
      }
    } catch (error) {
      console.error('Error fetching table data', error);
      return [];
    }
  };
  
  
 function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
// ordenar la selectedData, conté la info de les taules ordenades
const sortedData = selectedData.slice().sort((a, b) => a.Unix_Time - b.Unix_Time);

const tempGraphData = {
  labels: sortedData.map(item => processTimestamp(item.Timestamp)),
  datasets: selectedDevices.flatMap(deviceName => ([
    // {
    //   label: `Temperature (${deviceName}) - Ambient`,
    //   data: sortedData
    //     .filter(item => item.device === deviceName)
    //     .map(item => ({
    //       x: processTimestamp(item.Timestamp),
    //       y: item.Temp_Ambient,
    //     })),
    //   fill: false,
    //   borderColor: getRandomColor(),
    //   tension: 0.1,
    // },
    {
      label: `Temperature (${deviceName})`,
      data: sortedData
        .filter(item => item.device === deviceName)
        .map(item => ({
          x: processTimestamp(item.Timestamp),
          y: item.Temperature,
        })),
      fill: false,
      borderColor: '#FFFFFF', //getRandomColor(),
      tension: 0.1,
    }
  ])),
};
const humGraphData = {
  labels: sortedData.map(item => processTimestamp(item.Timestamp)),
  datasets: selectedDevices.map(deviceName => ({
    label: `Humidity (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item.Timestamp),
        y: item.Humidity,
      })),
    fill: false,
    borderColor: '#FFFFFF', //getRandomColor(),
    tension: 0.1,
  })),
};
const heightGraphData = {
  labels: sortedData.map(item => processTimestamp(item.Timestamp)),
  datasets: selectedDevices.map(deviceName => ({
    label: `Height (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item.Timestamp),
        y: item.Elevation,
      })),
    fill: false,
    borderColor: '#FFFFFF', //getRandomColor(),
    tension: 0.1,
  })),
};

const conGraphData = {
  labels: sortedData.map(item => processTimestamp(item.Timestamp)),
  datasets: selectedDevices.map(deviceName => ({
    label: `Consumption (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item.Timestamp),
        y: item.Power,
      })),
    fill: false,
    borderColor: '#FFFFFF', //getRandomColor(),
    tension: 0.1,
  })),
};


const presenceGraphData = [];

selectedDevices.forEach(deviceName => {
  const userTagIds = [...new Set(sortedData.filter(item => item.device === deviceName).map(item => item.Name))];
  
  const deviceGraphData = {
    labels: sortedData.map(item => processTimestamp(item.Timestamp)),
    datasets: [],
  };

  userTagIds.forEach(userTagId => {
    const userPresenceData = sortedData
      .filter(item => item.device === deviceName && item.Name === userTagId)
      .map(item => ({
        x: processTimestamp(item.Timestamp),
        y: item.Presence,
      }));

    deviceGraphData.datasets.push({
      label: `Presence - User ${userTagId}`,
      data: userPresenceData,
      fill: false,
      borderColor: getRandomColor(),
      tension: 0.1,
      steppedLine: 'before',
    });
  });

  presenceGraphData.push({ deviceName, graphData: deviceGraphData });
});

// Now presenceGraphData is an array of objects, each containing deviceName and graphData
// You can use this data to render separate graphs for each device in your UI

if (sortedData.length > 0){
  var temperatures = [];
  var humidity = [];
  var consumption = [];
  var tempMean = 0;
  var humMean = 0;
  var consMean = 0;
  sortedData.forEach(item => {
    temperatures.push(item.Temperature);
    humidity.push(item.Humidity);
    consumption.push(item.Power);
    tempMean += item.Temperature;
    humMean += item.Humidity;
    consMean += item.Power;
  });
  tempMean /= temperatures.length;
  humMean /= humidity.length;
  consMean /= consumption.length
}
console.log('sortedData:', sortedData);

return (
    <div className='principal'>
  
      <div className='container-opt_gesab'>
        <div className='checkbox-container_gesab' style ={{width: 550 +'px', paddingLeft: 10+'px'}}>
          <h2 style ={{marginTop: 15 +'px', marginRight: 25 + 'px', width: 175 + 'px'}}>Select Consoles to display</h2>
          {consoles.map(consoleName => (
                <div key={consoleName} className="console-label_gesab">
                <input
                    type="checkbox"
                    value={consoleName.Device_Name}
                    checked={selectedDevices.includes(consoleName.Device_Name)}
                    onChange={handleConsoleCheckboxChange}
                />
                {consoleName.Public_Name}
                </div>
            ))}
          <div className='button-wrapper_gesab'>
            <button className="apply-button_gesab" onClick={handleDisplaySelected}>Display</button>
          </div>
        </div>

        <div className="select-container_gesab">
            <div className='header-select_gesab'>
              <h2 style={{color: 'white'}}>Filter data by time</h2>
            </div>
            <div className="select-wrapper_gesab">
            <select onChange={(e) => handleTimeFilter(Number(e.target.value))}>
              <option value="" disabled selected>Select Time Filter</option>
              {timeFilterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='dates-button-container_gesab'>
          <div className="date-filter-container_gesab">
            <div className="date-filter_gesab">
              <h2 className="date_gesab">Start Date:</h2>
              <input
                type="date"
                id="startDate"
                value={startDateFilter}
                onChange={(e) => setStartDateFilter(e.target.value)}
              />
            </div>
            <div className="date-filter_gesab">
            <h2 className="date_gesab">End Date:</h2>
              <input
                type="date"
                id="endDate"
                value={endDateFilter}
                onChange={(e) => setEndDateFilter(e.target.value)}
              />
            </div>
            <div className='apply-button-wrapper_gesab'>
            <button className="apply-dates-button_gesab" onClick={handleDateFilter}>Apply Date Filter</button>
          </div>
          </div>
        </div>
      </div>


      <div className='container_gesab'>
        <div className='centered-content_gesab'>
          {/* <div style={{ display: 'flex', gap: '100px' }}> */}
            {selectedData.length > 0 && (
              <>
              <historic_section id="historic_box">
                <div className="graph-section_gesab">
                  <h2>CABINET TEMPERATURE</h2>
                  <hr></hr>
                    <div>
                      <span style={{fontFamily: 'Roboto'}}><span className='tab'></span>Min: {Math.min(...temperatures).toFixed(2)} ºC<span className='tab'></span>Mean: {tempMean.toFixed(2)} ºC<span className='tab'></span>Max: {Math.max(...temperatures).toFixed(2)} ºC</span>
                    </div>
                    <div className='graph'>
                      <Line data={tempGraphData} />
                    </div>
                </div>
              </historic_section>

              <historic_section id="historic_box">
                <div className="graph-section_gesab">
                  <h2>CABINET HUMIDITY</h2>
                  <hr></hr>
                    <div>
                      <span style={{fontFamily: 'Roboto'}}><span className='tab'></span>Min: {Math.min(...humidity).toFixed(2)} %<span className='tab'></span>Mean: {humMean.toFixed(2)} %<span className='tab'></span>Max: {Math.max(...humidity).toFixed(2)} %</span>
                    </div>
                    <div className='graph_gesab'>
                      <Line data={humGraphData} />
                    </div>
                </div>
              </historic_section>

              <historic_section id="historic_box" style = {{height: 400 + 'px'}}>
                <div className="graph-section_gesab">
                  <h2>TABLE HEIGHT</h2>
                  <hr></hr>
                    <div className='graph_gesab'>
                      <Line data={heightGraphData} />
                    </div>
                </div>
              </historic_section>
              
              <historic_section id="historic_box">
                <div className="graph-section_gesab">
                  <h2>ELECTRICITY CONSUMPTION</h2>
                  <hr></hr>
                    <div>
                      <span style={{fontFamily: 'Roboto'}}><span className='tab'></span>Min: {Math.min(...consumption).toFixed(2)} W<span className='tab'></span>Mean: {consMean.toFixed(2)} W<span className='tab'></span>Max: {Math.max(...consumption).toFixed(2)} W</span>
                    </div>
                    <div className='graph_gesab'>
                      <Line data={conGraphData} />
                    </div>
                </div>
              </historic_section>

              
              <historic_section id="historic_box" style = {{height: 400 + 'px'}}>
              {presenceGraphData.map(({ deviceName, graphData }) => (
                <div key={deviceName} className="graph-section_gesab">
                  <h2>{`PRESENCE`}</h2>
                  <hr></hr>
                    <div className='graph_gesab'>
                      <Line data={graphData} />
                    </div>
                </div>
                ))}
              </historic_section>

              

            </>
            )}
          {/* </div> */}
        </div>
      </div>
      
    </div>
  );
};

export default UsersDevicesTable;
