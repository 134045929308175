// Sorts the second vector according to he first vector values, from min to max

const Sort = (time, paquet, data) => {
    //1) combine the arrays:
    var list = [];
    for (var j = 0; j < time.length; j++) 
        list.push({'data': data[j], 'time': time[j], 'paquet': paquet[j]});

    //2) sort:
    list.sort(function(a, b) {
        return ((a.time < b.time) ? -1 : ((a.time > b.time) ? 1 : ((a.paquet < b.paquet) ? -1 : ((a.paquet > b.paquet) ? 1 : 0))));
    });

    //3) separate them back out:
    for (var k = 0; k < time.length; k++) {
        time[k] = list[k].time;
        data[k] = list[k].data;
		paquet[k] = list[k].paquet;
    }

    return {time: time, paquet: paquet, data: data};
};


export default Sort;
 