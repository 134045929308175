import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled'
import './App.css';
import './../../dashboard.css';
import {Helmet} from 'react-helmet';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';

// Cognito
import {Account} from './UserAuthentication/Accounts';

import WholeAuth from './UserAuthentication/whole_auth';

// Device selection menu

// Layout
import Header from './Layout/header';
import Footer from './Layout/footer';

// Context
import {UserContext} from './UserAuthentication/context';

// AWS functions
import {get_docClient} from './AWS-SDK/AWS';

import Cookies from "js-cookie";

// Error message
var err_msg = "Default error message!";

let docClient = get_docClient();

function App () {
	
	const App = styled.div`
        position: absolute;
        min-height: 100%;
        min-width: 100%;
    `
    const Container = styled.div`
        margin: 0 auto;
        margin-bottom: 40px;
        font-size: 40px;
    `
	
	// Screen state
	const [screen, setScreen] = useState("LOGIN"); // to control the screen to use
	
	// Login states
	const [isUserLogged, setIsUserLogged] = useState(false); // to check if the whole webpage should be shown or not
    const [userEmail, setUserEmail] = useState(''); // user Email
	
	// useHistory
	const history = useHistory();


	const handleLogout = () => {
		// Call the logout function from Accounts.js to handle the logout
		// (you may need to import the logout function from Accounts.js)
		// For example, if the logout function is exported as 'logout':
		// logout();
		setIsUserLogged(false)
		console.log("token cookie:", Cookies.get("idToken"));
		Cookies.remove("idToken");
		console.log("token cookie:", Cookies.get("idToken"));
	};
	useEffect(() => {
		if (userEmail && screen === 'LOGIN') {
		  // Redirect to the '/menu' route
		  history.push('/gesab');
		}
	  }, [userEmail, screen]);
	//   console.log(userEmail);
	//   console.log(screen);
	//   console.log(isUserLogged);
	//   console.log(docClient);

    return (
		<Router>   
        <div className="App">
			<Helmet>
				<title>Sterna eSpot Dashboard</title>
				<link rel="icon" href="/es.png" />
			</Helmet>
			{screen != "ERROR" ? (
				<UserContext.Provider value={{isUserLogged, setIsUserLogged, userEmail, setUserEmail} }>
					{isUserLogged && docClient ? (
							<App>
								<Header user={userEmail} handleLogout={handleLogout}/>
								
								<Footer />
							</App>
						
					) : (
						<Account>
							<Container>
								<WholeAuth />
							</Container>
						</Account>
					) }
				</UserContext.Provider>
			) : (
				<div> {err_msg} </div>
			)}
        </div>
		</Router>
    );  
}  



export default App;
