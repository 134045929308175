// ProfileMenu.js
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import styled from '@emotion/styled';

const ProfileMenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ProfileButton = styled.button`
  font-size: 1.5rem;
  font-weight: 500;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  line-height: 15px;
  margin-top: 18px;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: #2e2e2e;
  min-width: 160px;
  z-index: 1;
  margin-top: 10px;
`;

const DropdownItem = styled.button`
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
`;
/*const ProfileMenu = ({ handleLogout, handleChangePassword }) => {
 */
const ProfileMenu = ({ handleLogout,handleChangePassword }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <ProfileMenuContainer>
      <ProfileButton onClick={toggleDropdown}>Profile</ProfileButton>
      <DropdownContent show={showDropdown}>
        <DropdownItem onClick={handleLogout}>Log out</DropdownItem>
        <Link to="/settings">
          <DropdownItem >Change password</DropdownItem>
        </Link>
        </DropdownContent>
    </ProfileMenuContainer>
  );
};

export default ProfileMenu;
