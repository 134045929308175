import React from "react";
import styled from '@emotion/styled'

import MapAux from "./MapAux.js";


function Map(props) {

  const IdStyle = styled.div ` 
      padding-top: 11pt;
      padding-bottom: 12pt;
      background-color: #dedede;
  `


    return (
        <>
        {props.locations ? (
            <IdStyle>
                <MapAux locations={props.locations} dC={props.dC} />
            </IdStyle> 
        ) : (
            null
        )}
        </>
    )

}

export default Map;