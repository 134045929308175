import React, { useState, useEffect } from 'react';
import { get_docClient } from '../../AWS-SDK/AWS';
import { Line } from 'react-chartjs-2';
import "./graphs.css";
import Footer from '../Layout/footer';

const UsersDevicesTable = () => {

  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedData, setSelectedData] = useState([]); // Store data for selected devices
  
  const timeFilterOptions = [
    { value: 1, label: 'Last 24 hours' },
    { value: 2, label: 'Last 2 Days' },
    { value: 7, label: 'Last Week' },
    { value: 21, label: 'Last 3 Weeks' },
    { value: 30, label: 'Last Month' },
    { value: 60, label: 'Last 2 Months' },
    { value: 180, label: 'Last 6 Months' },
    { value: 365, label: 'Last Year' },
    { value: null, label: 'All'}
  ];
  useEffect(() => {
    fetchDevices(); 
  }, []);
  

  const fetchDevices = async () => {

    try {

      const docClient = get_docClient();
      const params = {
        TableName: 'Clear_Solar_Devices',
      };

      const data = await docClient.scan(params).promise();
      const deviceNames = data.Items.map(item => item.Device_Name);
      setDevices(deviceNames);
      console.log('devices', deviceNames);
    } catch (error) {
      console.error('Error fetching devices names', error);
    }

  };

  const handleDeviceCheckboxChange = (event) => {

    const deviceName = event.target.value;
    if (event.target.checked) {
      setSelectedDevices(prevSelected => [...prevSelected, deviceName]);
    } else {
      setSelectedDevices(prevSelected => prevSelected.filter(name => name !== deviceName));
    }
  };

  const processTimestamp = (timestamp) => {
    const [date, timeoooz] = timestamp.split('T');

    const [year, month, day] = date.split('-');
    const [time, oooz] = timeoooz.split('.');
    const [hour, minute, second] = time.split(':');
    const utcTimestamp = Date.UTC(year, month - 1, day, hour, minute, second);

    return new Date(utcTimestamp).toLocaleString();

  };

  
  const handleDisplaySelected = async () => {
    const mergedData = [];
    console.log('device names:', selectedDevices);
    for (const deviceName of selectedDevices) {
      const tableData = await fetchTableData(deviceName, null, null);
      const deviceData = tableData.map(item => ({
        ...item,
        device: deviceName,
      }));
      mergedData.push(...deviceData);
    }
    // Update the selectedData state with the merged data
    setSelectedData(mergedData); // Use await here
    console.log('selectedData:', selectedData);
  };
  
  
 const handleTimeFilter = async (numDays) => {
    const currentDate = new Date();
    const endDate = currentDate.toISOString().split('T')[0];
    
    const subtractedDate = new Date();
    subtractedDate.setDate(currentDate.getDate() - numDays);
    const startDate = subtractedDate.toISOString().split('T')[0];
  
    const startUnixTime = new Date(startDate).getTime() / 1000;
    const endUnixTime = (new Date(endDate).getTime() / 1000) + 86400; // Adding 24 hours to include the end day

    console.log('startUnixTime', startUnixTime);
    console.log('endUnixTime', endUnixTime);
    const filteredData = [];    
    for (const deviceName of selectedDevices) {
      const tableData = await fetchTableData(deviceName, startUnixTime, endUnixTime);
      const deviceData = tableData.map(item => ({
        ...item,
        device: deviceName,
      }));
      filteredData.push(...deviceData);
    }
    setSelectedData(filteredData);
    console.log('selectedData after filtering', selectedData);
  }; 

  const fetchTableData = async (deviceName, startUnixTime, endUnixTime) => {
    console.log('deviceName: ', deviceName);
    try {
      const docClient = get_docClient();
      const params = {
        TableName: deviceName,
      };
      const data = await docClient.scan(params).promise();
      console.log('data: ', data.Items);
     
      if (startUnixTime !== null && endUnixTime !== null) {
          // Filter the combined data for all selected devices
        const filteredData = data.Items.filter(item => {
        const unixTime = item.Unix_Time; // Replace with the actual attribute name in your table
        return (!startUnixTime || unixTime >= startUnixTime) &&
                   (!endUnixTime || unixTime <= endUnixTime);
      });
  
        return filteredData;
      } else {
        // If startUnixTime and endUnixTime are not provided, return all data
        return data.Items;
      }
    } catch (error) {
      console.error('Error fetching table data', error);
      return [];
    }
  };
  

 // Update your dosesGraphData and batteryGraphData like this:
 function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
// console.log('selectedData before sorting:', selectedData);
// ordenar la selectedData, conté la info de les taules ordenades
const sortedData = selectedData.slice().sort((a, b) => a.Unix_Time - b.Unix_Time);

const batteryGraphData = {
  labels: sortedData.map(item => processTimestamp(item["@timestamp"])), // Access @timestamp with square brackets
  datasets: selectedDevices.map(deviceName => ({
    label: `Battery (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item["@timestamp"]), // Access @timestamp with square brackets
        y: item.battery,
      })), // Map doses data for the device with x and y values
    fill: true,
    borderColor: getRandomColor(), // Generate a color for the line
    tension: 0.1,
    steppedLine: 'before',
    pointRadius: 0,
    pointHoverRadius: 0,
  })),
};

const energyGraphData = {
  labels: sortedData.map(item => processTimestamp(item["@timestamp"])),
  datasets: selectedDevices.map(deviceName => ({
    label: `Energy (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item["@timestamp"]),
        y: item.energy,
      })),
      fill: false,
      borderColor: getRandomColor(), // Generate a color for the line
      tension: 0.1,
      pointRadius: 0,
      pointHoverRadius: 0,
  })),
};

const temperatureGraphData = {
  labels: sortedData.map(item => processTimestamp(item["@timestamp"])),
  datasets: selectedDevices.map(deviceName => ({
    label: `Temperature (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item["@timestamp"]),
        y: item.temperature,
      })),
      fill: false,
      borderColor: getRandomColor(), // Generate a color for the line
      tension: 0.1,
      pointRadius: 0,
  })),
};

const humidityGraphData = {
  labels: sortedData.map(item => processTimestamp(item["@timestamp"])),
  datasets: selectedDevices.map(deviceName => ({
    label: `Humidity (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item["@timestamp"]),
        y: item.humidity,
      })),
    fill: false,
    borderColor: getRandomColor(), // Generate a color for the line
    tension: 0.1,
    pointRadius: 0,
    pointHoverRadius: 0,
  })),
};

const voltageGraphData = {
  labels: sortedData.map(item => processTimestamp(item["@timestamp"])),
  datasets: selectedDevices.map(deviceName => {
    const deviceData = sortedData.filter(item => item.device === deviceName);
    const voltageMeanData = deviceData.map(item => ({
      x: processTimestamp(item["@timestamp"]),
      y: item.voltageMean,
    }));
    const voltageMaxData = deviceData.map(item => ({
      x: processTimestamp(item["@timestamp"]),
      y: item.voltageMax,
    }));

    return [
      {
        label: `Voltage Mean (${deviceName})`,
        data: voltageMeanData,
        fill: false,
        borderColor: getRandomColor(), // Generate a color for the line
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      {
        label: `Voltage Max (${deviceName})`,
        data: voltageMaxData,
        fill: false,
        borderColor: getRandomColor(), // Generate a color for the line
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ];
  }).flat(), // Use .flat() to flatten the array of datasets
};

const powerGraphData = {
  labels: sortedData.map(item => processTimestamp(item["@timestamp"])),
  datasets: selectedDevices.map(deviceName => {
    const deviceData = sortedData.filter(item => item.device === deviceName);
    const powerMeanData = deviceData.map(item => ({
      x: processTimestamp(item["@timestamp"]),
      y: item.powerMean,
    }));
    const powerMaxData = deviceData.map(item => ({
      x: processTimestamp(item["@timestamp"]),
      y: item.powerMax,
    }));

    return [
      {
        label: `Power Mean (${deviceName})`,
        data: powerMeanData,
        fill: false,
        borderColor: getRandomColor(), // Generate a color for the line
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      {
        label: `Power Max (${deviceName})`,
        data: powerMaxData,
        fill: false,
        borderColor: getRandomColor(), // Generate a color for the line
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ];
  }).flat(), // Use .flat() to flatten the array of datasets
};

const currentGraphData = {
  labels: sortedData.map(item => processTimestamp(item["@timestamp"])),
  datasets: selectedDevices.map(deviceName => {
    const deviceData = sortedData.filter(item => item.device === deviceName);
    const currentMeanData = deviceData.map(item => ({
      x: processTimestamp(item["@timestamp"]),
      y: item.currentMean,
    }));
    const currentMaxData = deviceData.map(item => ({
      x: processTimestamp(item["@timestamp"]),
      y: item.currentMax,
    }));

    return [
      {
        label: `Current Mean (${deviceName})`,
        data: currentMeanData,
        fill: false,
        borderColor: getRandomColor(), // Generate a color for the line
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      {
        label: `Current Max (${deviceName})`,
        data: currentMaxData,
        fill: false,
        borderColor: getRandomColor(), // Generate a color for the line
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ];
  }).flat(), // Use .flat() to flatten the array of datasets
};
const measuredLossGraphData = {
  labels: sortedData.map(item => processTimestamp(item["@timestamp"])),
  datasets: selectedDevices.map(deviceName => ({
    label: `MeasuredLoss (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item["@timestamp"]),
        y: item.measuredLoss,
      })),
      fill: false,
      borderColor: getRandomColor(), // Generate a color for the line
      tension: 0.1,
      pointRadius: 0,
      pointHoverRadius: 0,
     // borderWidth: 0.8,
  })),
};

const radiationGraphData = {
  labels: sortedData.map(item => processTimestamp(item["@timestamp"])),
  datasets: selectedDevices.map(deviceName => ({
    label: `Radiation (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item["@timestamp"]),
        y: item.radiation,
      })),
      pointRadius: 2,
      fill: false,
      borderColor: getRandomColor(), // Generate a color for the line
      tension: 0.1,
  })),
};


const messagesGraphData = {
  labels: sortedData.map(item => processTimestamp(item["@timestamp"])),
  datasets: selectedDevices.map((deviceName, index) => ({
    label: `Messages frequency (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item["@timestamp"]),
        y: index + 1,
      })),
    pointRadius: 5,
    pointHoverRadius: 8,
    pointBackgroundColor: getRandomColor(),
    fill: false,
  })),
  type: 'scatter',
};
//console.log('sortedData:', sortedData);

return (
  <div>
      <div className="header-container">
        <header className="auth-app-header">
            <div className="auth-logo">
                <img src={"/imgs/logo-sterna-st.png"} alt="Logo" />
                <h1 className="teva">
                    <span>Clear Solar Dashboard</span>
                </h1>
            </div>
        </header>
      </div>
      
        <div className='checkbox-container'>
          <h2>Select devices to display</h2>
            {devices.map(deviceName => (
              <label key={deviceName} className="device-label">
                <input
                  type="checkbox"
                  value={deviceName}
                  checked={selectedDevices.includes(deviceName)}
                  onChange={handleDeviceCheckboxChange}
                  className="checkbox-input"

                />
                <span className="device-name">{deviceName}</span>
              </label>
            ))}

            <div className='button-wrapper'>
              <button className="apply-button" onClick={handleDisplaySelected}>Display</button>
            </div>
            <select onChange={(e) => handleTimeFilter(Number(e.target.value))}>
              <option value="" disabled selected>Select Time Filter</option>
              {timeFilterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
      
          <div className='centered-content'>
            {selectedData.length > 0 && (
              <>
              <div className="graph-container">

                <div className="graph-section">
                <h2>Messages Received (freq.)</h2>
                  <div style={{ width: '900px', height: '600px', marginBottom: '5px' }}>
                    <Line data={messagesGraphData} />
                  </div>
                </div>

                <div className="graph-section">
                  <h2>Battery (%)</h2>
                  <div style={{ width: '900px', height: '600px', marginBottom: '5px' }}>
                    <Line data={batteryGraphData} />
                  </div>
                </div>

                <div className="graph-section">
                <h2>Energy (Wh)</h2>
                  <div style={{ width: '900px', height: '600px', marginBottom: '5px' }}>
                    <Line data={energyGraphData} />
                  </div>
                </div>

                <div className="graph-section">
                  <h2>Voltage (V)</h2>
                  <div style={{width: '900px', height: '600px', marginBottom: '5px'}}>
                    <Line data={voltageGraphData} />
                  </div>
                </div>
                
                <div className="graph-section">
                  <h2>Power (W)</h2>
                  <div style={{width: '900px', height: '600px', marginBottom: '5px'}}>
                    <Line data={powerGraphData} />
                  </div>
                </div>
                
                <div className="graph-section">
                  <h2>Current (A)</h2>
                  <div style={{width: '900px', height: '600px', marginBottom: '5px'}}>
                    <Line data={currentGraphData} />
                  </div>
                </div>

                <div className="graph-section">
                <h2>Temperature (Celsius)</h2>
                  <div style={{ width: '900px', height: '600px', marginBottom: '5px' }}>
                    <Line data={temperatureGraphData} />
                  </div>
                </div>

                <div className="graph-section">
                <h2>Humidity (rel. %)</h2>
                  <div style={{ width: '900px', height: '600px', marginBottom: '5px' }}>
                    <Line data={humidityGraphData} />
                  </div>
                </div>

                <div className="graph-section">
                <h2>measuredLoss (%)</h2>
                  <div style={{ width: '900px', height: '600px', marginBottom: '5px' }}>
                    <Line data={measuredLossGraphData} />
                  </div>
                </div>

                <div className="graph-section">
                <h2>radiation (mA solar cell)</h2>
                  <div style={{ width: '900px', height: '600px', marginBottom: '5px' }}>
                    <Line data={radiationGraphData} />
                  </div>
                </div>

              </div>

            </>
            )}
          </div>
    </div>

  );
};


export default UsersDevicesTable;