import React, {useState, useContext, useEffect} from 'react';
import styled from '@emotion/styled'

import GraphSection from './graph_section'
import ButtonDownloadExcel from './buttons/ButtonDownloadExcel';
import ButtonDownloadPDF from './buttons/ButtonDownloadPDF';

import {SelectContext} from './../Selection/context';

// Sort function
import Sort from './../../utils/sort';

// AWS functions
import {scanTable, get_docClient} from './../../AWS-SDK/AWS';

let docClient = get_docClient();

function unixDate(unixtime) {

    var u = new Date(unixtime*1000);

      return u.getFullYear() +
        '-' + ('0' + (u.getMonth()+1)).slice(-2) +
        '-' + ('0' + u.getDate()).slice(-2)
};

const Content = (props) => {

    const Button = styled.div ` 
        background-color:#ffffff;
        margin: 0 auto;
        margin-left: 5pt;
    `

    const SectionHeader = styled.div ` 
        background-color: #394475;

        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
        list-style: none;
        line-height: 20px;

        padding: 3px 10px 3px 10px;
        border-radius: 0pt;
        border-color: #000000;
        border-width: 0pt;
        border-style: solid;

        position: sticky;
        margin: 0 auto;
        vertical-align: middle;
        top: 55px;

        z-index: 102;
    `
	
	const BlankSpace = styled.div ` 
        background-color: #FFFFFF;
        display: flex;
        padding: 4px 0px 0px 0px;
        position: sticky;
        margin: 0 auto;
        top: 96px;
        z-index: 102;
    `
	
	const NoData = styled.div ` 
        background-color: #FFFFFF;
        padding: 10px 0px 0px 0px;
        position: sticky;
        margin: 0 auto;
        top: 100px;
        z-index: 102;
		font-size: 20px;
		text-align: center;
		font-weight: 500;
    `

    const Section = styled.div`
        width: 70%; /* width of the content of the whole page */
        margin: 0 auto;
        margin-bottom: 150px;
    `

    const SectionD = styled.div`
        width: 100%; /* width of the content of the whole page */
        margin: 0 auto;
        margin-bottom: 20pt;
    `

    const MarginHeader = styled.div ` 
        margin-top: 4pt;
        line-height: 20px;
        color: white;
    `
	
	const FileIcon = styled.div`
		text-anchor: middle;
		line-height: 0px;       
		margin-top: 2pt;
		margin-bottom: 1pt
    `

    const {isChoiceSubmitted, setIsChoiceSubmitted, selectedDevices, setSelectedDevices} = useContext(SelectContext);
	const [devicesData, setDevicesData] = useState(null);
	const [auxState, setAuxState] = useState(null);
	const [orderedData, setOrderedData] = useState(null);
	
	var devNames = props.devNames;
	var paramNames = props.paramNames;
	
	var now = new Date();
	var current_time = now.getTime()/1000;
	
	const [startTime, setStartTime] = useState(current_time - 2592000); // 1 month by default
	const [endTime, setEndTime] = useState(current_time + 86400); // add 1 day to show current day data
	const [dateCodes, setDateCodes] = useState([]);
	const startDate = React.useRef();
	const endDate = React.useRef();
	
	const [variableList, setVariableList] = useState(null);
	const [boolShowFilter, setBoolShowFilter] = useState(true);
	
	useEffect(()=>{
		if (dateCodes.length > 0){
			var devData = {};
			var content = [];
			var timeUnix = [];
			var paquets = [];
			var variablesList = [];
			// scan all selected devices tables
			for (let i=0; i<selectedDevices.length; i++){
				var devices = Object.keys(devNames);
				if (devices.includes(selectedDevices[i])){
					devData[devNames[selectedDevices[i]]] = [];
				}
				else {
					devData[selectedDevices[i]] = [];
				}
				for (let n=0; n<dateCodes.length; n++){
					//console.log("Scan " + "eC_".concat(selectedDevices[i], "_", dateCodes[n]));
					scanTable("eC_".concat(selectedDevices[i], "_", dateCodes[n])).then(res => {
						if (res.exist){
							// rename variables and devices while ordering data
							content = res.items;
							for (var j in content){
								// rename variables
								var variables = Object.keys(content[j]);
								var params = Object.keys(paramNames);
								for (var k in variables){
									if (params.includes(variables[k])){
										content[j][paramNames[variables[k]]] = content[j][variables[k]];
										delete content[j][variables[k]];
										// limit distance values
										if (paramNames[variables[k]] == 'Distance' && parseFloat(content[j][paramNames[variables[k]]]) > 1900){
											content[j][paramNames[variables[k]]] = 0;
										}
									}
									// save non repeated variables in list
									if (!variablesList.includes(paramNames[variables[k]])){
										variablesList.push(paramNames[variables[k]]);
									}
								}
							}
							// rename devices while saving data
							if (devices.includes(selectedDevices[i])){
								devData[devNames[selectedDevices[i]]] = devData[devNames[selectedDevices[i]]].concat(content);
							}
							else {
								devData[selectedDevices[i]] = devData[selectedDevices[i]].concat(content);
							}
							setAuxState(i);							
						}
						else{
							//console.log("Table does not exist");
						}
						if (i == selectedDevices.length - 1 && n == dateCodes.length - 1) setAuxState(999);
					});
				}
			}
			setVariableList(variablesList);
			setDevicesData(devData);
		}
    }, [dateCodes]);
	
	useEffect(()=>{
		if (devicesData && auxState == 999){
			var ordData = {};
			var content = [];
			var timeUnix = [];
			var paquets = [];
			var devices = Object.keys(devicesData);
			for (let i in devices){
				content = devicesData[devices[i]];
				for (let j in devicesData[devices[i]]){
					timeUnix[j] = content[j].Timestamp;
					paquets[j] = content[j].Pack;
				}
				// order data
				var Sortout = Sort(timeUnix, paquets, content);
				// save ordered data
				ordData[devices[i]] = Sortout.data;
			}
			//console.log("Data ordered");
			//console.log(ordData);
			setOrderedData(ordData);
		}
	}, [auxState]);
	
	useEffect(()=>{
		// find months and years to read
		var start_date = new Date(startTime*1000)
		var end_date = new Date(endTime*1000)
		var start_month = start_date.getMonth() + 1;
		var start_year = start_date.getFullYear();
		var end_month = end_date.getMonth() + 1;
		var end_year = end_date.getFullYear();
		var date_codes = [];
		var year = start_year;
		var month = start_month;
		let y = start_year;
		let m = start_month;
		for (let y=start_year; y<=end_year; y++){
			if (y < end_year){
				for (let m=start_month; m<=12; m++){
					date_codes.push(('0' + y).slice(-2) + ('0' + m).slice(-2));
				}
			}
			else{
				for (let m=start_month; m<=end_month; m++){
					date_codes.push(('0' + y).slice(-2) + ('0' + m).slice(-2));
				}
			}
			start_month = 1;
		}
		var changed = false;
		if (date_codes.length != dateCodes.length) changed = true;
		else{
			for (let i in date_codes){
				if (date_codes[i] != dateCodes[i]){
					changed = true;
					break;
				}
			}
		}
		if (changed) setDateCodes(date_codes);
	}, [startTime,endTime]);

    const clickFilter = () => {
        var start = startDate.current.value.split("-");
		var end = endDate.current.value.split("-");
		start = new Date( start[0], start[1] - 1, start[2]);
		end = new Date( end[0], end[1] - 1, end[2]);
		setStartTime(start.getTime()/1000);
		setEndTime(end.getTime()/1000);
    }
	
	const BackFunc = () => {
        setIsChoiceSubmitted(false);
    }
	
	const ToggleFilter = () => {
        setBoolShowFilter(!boolShowFilter);
    }

    return ( 
        <Section>		
            <SectionHeader>
					<li>
						<MarginHeader>
							{"All data"}
							<Button onClick={ToggleFilter} className="generalbutton" > Toggle  </Button>
						</MarginHeader>
					</li>
					{boolShowFilter ? (
						<>
							<li>
								<MarginHeader>
									{"Start date: "}
									<input type="date" ref={startDate} defaultValue={unixDate(startTime)}/>
									{" End date: "}
									<input type="date" ref={endDate} defaultValue={unixDate(endTime)}/>
									<Button onClick={clickFilter} className="generalbutton"> Apply </Button>
								</MarginHeader>
							</li>
							<li>
								<ButtonDownloadPDF variable={"All"}/>
								<ButtonDownloadExcel variable={"All"} dataFiltered={orderedData} /> 
								<Button onClick={() => {BackFunc()}} className="generalbuttonicon" >
									<FileIcon>
										<img src={"/imgs/Home.svg"} width="25px" alt="Home"></img>
									</FileIcon>
								</Button>
							</li>
						</>
					) : (
						<li>
							<Button onClick={() => {BackFunc()}} className="generalbuttonicon" >
								<FileIcon>
									<img src={"/imgs/Home.svg"} width="25px" alt="Home"></img>
								</FileIcon>
							</Button>
						</li>
					)}
			</SectionHeader>
			
			<BlankSpace>
			</BlankSpace>
			
			{variableList && startTime && endTime && boolShowFilter && orderedData ? (
				<div id={"All"}>

					{variableList.includes("Temperature") ? ( <GraphSection variable={"Temperature"} startTime={startTime} endTime={endTime} devicesData={orderedData} /> ) : (null)}
					{variableList.includes("Acceleration") ? ( <GraphSection variable={"Acceleration"} startTime={startTime} endTime={endTime} devicesData={orderedData} /> ) : (null)}
					{variableList.includes("Distance") ? ( <GraphSection variable={"Distance"} startTime={startTime} endTime={endTime} devicesData={orderedData} /> ) : (null)}
					{variableList.includes("Battery") ? ( <GraphSection variable={'Battery'} startTime={startTime} endTime={endTime} devicesData={orderedData} /> ) : (null)}
					{!variableList.includes("Temperature") && !variableList.includes("Acceleration") && !variableList.includes("Distance") && !variableList.includes("Battery") ? (<NoData>No data registered during selected time period</NoData>) : (null)}

				</div>
			) : (null)}

        </Section>
     );
}
 
export default Content;
