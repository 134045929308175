import React from 'react';
import ReactDOM from 'react-dom';

//import './navigation.css';
import './dashboard.css';
//import './index.css';

import * as serviceWorker from './serviceWorker';
import { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App_EC from './eContainer/App';
import App_IoT from './IoT/App';
import menu from './menu';
import App_EE from './eEfficiency/App';
import App_ES from './eSpot/App';
import App_AM from './AdminMode/App';
import App_T from './Teva/App';
import App_CS from './clearSolar/App';
import AppLGe from './gesab/loginSignup/App';
import AppLCl from './clearSolar/loginSignup/App';
import AppL from './loginSignup/App';
import App_P from './Settings/App';
import App_G from './gesab/App';
//import Navigation from './Navigation';
import HttpsRedirect from 'react-https-redirect';
/*<Route exact path='/' component={Navigation} />*/

ReactDOM.render(
  <React.StrictMode>
	<HttpsRedirect>
	<BrowserRouter>
		<Switch>
			<Route exact path='/' component={menu} />
			<Route path='/login' component={AppL} title="login"/>
			<Route path='/iot' component={App_IoT} title="IoT"/>
			<Route path='/econtainer' component={App_EC} title="eContainer"/>
			<Route path='/eefficiency' component={App_EE} title="eEfficiency"/>
			<Route path='/espot' component={App_ES} title="eSpot"/>
			<Route path='/admin' component={App_AM} title="admin"/>
			<Route path='/wifipoc' component={App_T} title="wifipoc"/>
			<Route path='/clearsolar' component={App_CS} title="clearSolar"/>
			<Route path='/loginGe' component={AppLGe} title="smartConsoleLogin"/>
			<Route path='/loginCl' component={AppLCl} title="cirsolarLogin"/>
			<Route path='/settings' component={App_P} title="account settings"/>
			<Route path='/gesab' component={App_G} title="gesab"/>
			
		</Switch> 
	</BrowserRouter>
	</HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
