import React from 'react';
import styled from '@emotion/styled';

const FooterContainer = styled.div`
    font-weight: 500;
    font-size: 0.8em;
    text-align: center;
    padding: 1em 0;
    color: white;
    background: linear-gradient(to right, rgb(23, 36, 62), #32415C, #2a4475);
    height: 110px;
    border-radius: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
`;

const Copyright = styled.div`
    margin-bottom: 0.5em;
`;
const LogoLink = styled.a`
    width: 150px; /* Adjust the width as needed */
    display: flex;
    justify-content: center;
`;

const Logo = styled.img`
    width: 150px; /* Adjust the width as needed */
    height: auto;
    margin-top: 0.5em; /* Add some margin to separate the logo from the copyright */
`;

const Footer = () => {
    return (
        <FooterContainer>
            <Copyright>
                Copyright &copy;{new Date().getFullYear()} Sterna Innovation Projects - All Rights Reserved
            </Copyright>
            <LogoLink href="http://sternainnovation.com/" target="_blank" rel="noopener noreferrer">
                <Logo src="/imgs/sterna-logo-w.png" alt="Sterna Innovation Projects" />
            </LogoLink>                    
        </FooterContainer>
    );
}

export default Footer;
