import React, { createContext, useState, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./UserPool";
import Cookies from "js-cookie";

const AccountContext = createContext();

const Account = props => {
  const [idToken, setIdToken] = useState(() => {
    // Retrieve the idToken from a cookie on component initialization
    return Cookies.get("idToken") || null;
  });
  // getSession és una funció asíncrona que té una promise que espera ser resolta 
  const getSession = async () =>
    await new Promise((resolve, reject) => {
      // s'agafa l'usuari actual
      const user = Pool.getCurrentUser();
      // si hi ha usuari, es crida getSession
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              // S'agafen els atributs de l'usuari, si no hi ha errors
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });

            resolve({
              user,
              ...session,
              ...attributes
            });
          }
        });
      } else {
        reject();
      }
    });

  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: data => {
          // guardem l'idtoken i inicialitzem la variable d'estat amb setIdToken
          const idToken = data.getIdToken().getJwtToken();
          setIdToken(idToken); // Use setIdToken to set the idToken state
          resolve(data);
          Cookies.set("idToken", idToken, { expires: 7 }); 
          console.log("token cookie:", Cookies.get("idToken"));
          console.log("token id:", idToken);
          console.log("onSuccess:", data);
        },

        onFailure: err => {
          // console.error("onFailure:", err);
          reject(err);
        },

        newPasswordRequired: data => {
          // console.log("newPasswordRequired:", data);
          resolve(data);
        }
      });
    });

    useEffect(() => {
      if (idToken) {
        Cookies.set("idToken", idToken, { expires: 7 }); // Store the idToken in a cookie that expires in 7 days
        console.log("token cookie:", Cookies.get("idToken"));
        console.log("token id:", idToken);
      }
    }, [idToken]);
    
  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
