import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import './App.css';
// Cognito
import { Account } from './components/UserAuthentication/Accounts';
import WholeAuth from './components/UserAuthentication/whole_auth';


// LayouAt
import Content from './components/Layout/content';
import Header from './components/Layout/header';
import Footer from './components/Layout/footer';

// Context
import { UserContext } from './components/UserAuthentication/context';
import { SelectContext } from './components/Selection/context';

// Sort function
import Sort from './utils/sort';

// AWS functions
import UsersDevicesTable from './components/Selection/admin_table';
// Third-Party Libraries
import Cookies from 'js-cookie';

// Error message
var err_msg = "Default error message!";


function App () {
	
	const App = styled.div`
        position: absolute;
        min-height: 100%;
        min-width: 100%;
    `

    const Container = styled.div`
        margin: 0 auto;
        margin-bottom: 40px;
        font-size: 40px;
    `
	
	// Screen state
	const [screen, setScreen] = useState("LOGIN"); // to control the screen to use
	
	// Login states
	const [isUserLogged, setIsUserLogged] = useState(false); // to check if the whole webpage should be shown or not
    const [userEmail, setUserEmail] = useState(''); // user Email

	  // Function to check if the user is an admin
	  useEffect(() => {
		// Check if the user is already logged in (using Cookies or other authentication mechanism)
		const idToken = Cookies.get('idToken');
		if (idToken) {
		  // User is already logged in, check if they are the admin user
		  const isAdminUser = checkAdminUser();
		  if (isAdminUser) {
			// If the user is the admin, set the isUserLogged state
			setIsUserLogged(true);
		  } else {
			// If the user is not the admin, log them out
			handleLogout();
		  }
		} else {
		  // User is not logged in, show the login screen
		  setScreen('LOGIN');
		}
	  }, []);
	const checkAdminUser = () => {
		// Add your logic here to check if the userEmail is the admin user
		// For example, if the admin user email is 'admin@example.com':
		return userEmail === 'mtorrent@sternainnovation.com';
	};
 
	const handleLogout = () => {
		console.log("token cookie:", Cookies.get("idToken"));
		Cookies.remove("idToken");
		console.log("token cookie:", Cookies.get("idToken"));
		setIsUserLogged(false);
	};

    return (  
        <div className="App">
			<Helmet>
				<title>Sterna Administrator mode</title>
			</Helmet>
			{screen != "ERROR" ? (
				<UserContext.Provider value={{isUserLogged, setIsUserLogged, userEmail, setUserEmail} }>
					{isUserLogged ? (
						<App>
							<Header user={userEmail} handleLogout={handleLogout}/>
							<UsersDevicesTable />
							<Footer />
						</App>
				
					) : (
						<Account>
							<Container>
								<WholeAuth />
							</Container>
						</Account>
					) }
				</UserContext.Provider>
			) : (
				<div> {err_msg} </div>
			)}
        </div>
    );  
}  



export default App;

// https://www.quora.com/What-are-some-good-ways-to-extract-one-single-column-from-a-DynamoDB-table
