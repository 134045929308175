// S'importa la llibreria "aws-sdk" que proporciona les funcionalitats per a interactuar amb els serveis d'aws des de node.js
// S'importa el fitxer de credentials, per tenir la clau d'accés i la clau secreta i autenticar les solicituds als serveis d'aws
var AWS = require("aws-sdk");
var credentials = require('./credentials.json'); //(with path)

// S'actualitza la configuració d'AWS amb les credencials, s'estableix la clau id, la clau secreta, la regió i la versió
AWS.config.update({
    accessKeyId: credentials.aws_access_key_id,
    secretAccessKey: credentials.aws_secret_access_key,
    region:'eu-west-1',
    version: 'latest'
});
// s'obtenen les credencials, si hi ha cap error al carregar-les, es mostra l'error
AWS.config.getCredentials(function(err) {
    if (err) { console.log("credentials not loaded: " + err.stack);}
    // else { console.log("AWS Access key:", AWS.config.credentials.accessKeyId); }
});
// es crea una instància de dynamodb, per poder interactuar-hi
let docClient = new AWS.DynamoDB.DocumentClient({correctClockSkew: true});

// scanTable Function returns table existence and its items, scanResults guarda els resultats obtinguts al escanejar la web de forma paginada, s'actualitza ExclusiveStartKey per obtenir la següent pag, aixo en bucle fins que no queden pags.
export const scanTable = async (tableName) => {
    const params = {
        TableName: tableName,
		};
    let scanResults = [];
	let lastkey;
	do{
		await (docClient.scan(params).promise()
			.then((data)=>{
				data.Items.forEach((item) => {scanResults.push(item)});
				params.ExclusiveStartKey = data.LastEvaluatedKey;
				lastkey = data.LastEvaluatedKey;
			})
			.catch((err)=>{
				return {'exist':false, 'items':[]};
			}));
	}while (typeof lastkey != "undefined");
    return {'exist':true, 'items':scanResults};
};

// return docClient, instància d'aws.dynamodb.documentclient per interactuar amb la bd de dynamodb a través de mètodes

export function get_docClient(){
	return docClient;
}
 