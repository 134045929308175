import React from 'react';
import styled from '@emotion/styled'

import Chart from './chart'

import ConfigJson from './../../utils/loadjson'; // configuration file

function unixTime(unixtime) {

    var u = new Date(unixtime*1000);

      return u.getFullYear() +
        '-' + ('0' + (u.getMonth()+1)).slice(-2) +
        '-' + ('0' + u.getDate()).slice(-2) + 
        ' ' + ('0' + u.getHours()).slice(-2) +
        ':' + ('0' + u.getMinutes()).slice(-2) +
        ':' + ('0' + u.getSeconds()).slice(-2)
};

const ChartSummoner = (props) => {
    var variable = props.variable; 

    var dataFiltered = props.dataFiltered;
	
	var dataMatrix = [];
	var k = 0;
	
	for (var device in dataFiltered){
		dataMatrix[k] = {};
		dataMatrix[k]['time'] = [];
		dataMatrix[k][variable] = [];
		dataMatrix[k]['device'] = device;
		for (var i in dataFiltered[device]){
			if (dataFiltered[device][i][variable]){
				dataMatrix[k]['time'].push(unixTime(dataFiltered[device][i]['Timestamp']));
				dataMatrix[k][variable].push(dataFiltered[device][i][variable]);
			}
		}
		k++;
	}

        const GraphContainer = styled.div`
            margin: 0 auto;
            /* padding-top: 4px; */
            padding-bottom: 1.5px;
            background-color: white;

            /* min-width: 50; */
            /* max-width: 900pt; */
            width: 100%;
            align-items: center;
            border-radius: 0px;
        `
    
    return ( 
        <GraphContainer>
            {dataMatrix ? (
                <Chart  variable={variable} config={ConfigJson} dataMatrix={dataMatrix} />   
            ) : (null)}
        </GraphContainer>
    );
}
 
export default ChartSummoner;