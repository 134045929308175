import React from 'react'
import styled from '@emotion/styled'

import AverageContainer from './data_blocks/average' 
import MaxContainer from './data_blocks/max'
import MinContainer from './data_blocks/min'

import ConfigJson from './../../utils/loadjson';

 
const DataContainers = (props) => {
    var variable = props.variable;
    var wholeData = JSON.parse(props.wholeData);
    var dt_k = props.dt_k;
    var d_k_sub = props.d_k_sub;

    var averages = [];
    var k = 0;

    var min = 100000; var time_min = "NaN";
    var max = 0; var time_max = "NaN";

    if (variable) {

        if (wholeData) {
            var device_list = []; // contains a list of non-repeated devices
    
            for (var j in dt_k) { // iterate through device types
                for (var i in d_k_sub[j]) { // iterate through devices in each device type
        
                    // check if device exists, in order to avoid repetition
                    var is_repeated = 0;
                    for (var z in device_list){
                        if (d_k_sub[j][i][0] === device_list[z]) { // device already exists
                            is_repeated++;
                        }
                    }
                    
                    // is_repeated=0;
                    if (is_repeated === 0) { // device doesn't exist
                        device_list.push(d_k_sub[j][i]);

                        var acum_area = 0;
                        if (typeof(wholeData[dt_k[j]][i][d_k_sub[j][i]][0]) != "undefined") {
                                for (var h in wholeData[dt_k[j]][i][d_k_sub[j][i]]) { // iterate through each row of the given device
                                    var value = parseFloat(wholeData[dt_k[j]][i][d_k_sub[j][i]][h][variable]);
                                    if (!isNaN(value)) {
                                        if (!ConfigJson[variable]['accumulated_graph']) {
                                            if (value >= max) {max = value; time_max = wholeData[dt_k[j]][i][d_k_sub[j][i]][h]['time']}
                                            if (value <= min) {min = value; time_min = wholeData[dt_k[j]][i][d_k_sub[j][i]][h]['time']}
                                        }
                                        if (h>= 1 && !isNaN(parseFloat(wholeData[dt_k[j]][i][d_k_sub[j][i]][h-1][variable]))) {
                                            if (!ConfigJson[variable]['accumulated_graph']) {
                                                acum_area += 0.5*(value + parseFloat(wholeData[dt_k[j]][i][d_k_sub[j][i]][h-1][variable])) * ( parseInt(wholeData[dt_k[j]][i][d_k_sub[j][i]][h].time_unix) - parseInt(wholeData[dt_k[j]][i][d_k_sub[j][i]][h-1].time_unix) ); 
                                            } else {
                                                var current_value = parseFloat(wholeData[dt_k[j]][i][d_k_sub[j][i]][h][variable]);
                                                var past_value = parseFloat(wholeData[dt_k[j]][i][d_k_sub[j][i]][h-1][variable]);
                                                var diff_value = current_value - past_value;
                                                if (diff_value >= max) {max = diff_value; time_max = wholeData[dt_k[j]][i][d_k_sub[j][i]][h]['time']}
                                                if (diff_value <= min) {min = diff_value; time_min = wholeData[dt_k[j]][i][d_k_sub[j][i]][h]['time']}                                               
                                                acum_area += 0.5*(current_value + past_value - 2*Math.min(current_value, past_value)) * ( parseInt(wholeData[dt_k[j]][i][d_k_sub[j][i]][h].time_unix) - parseInt(wholeData[dt_k[j]][i][d_k_sub[j][i]][h-1].time_unix) ); 
                                            }
                                        }
                                    }
                                if (acum_area > 0) {
                                    averages[k] = acum_area/(parseInt(wholeData[dt_k[j]][i][d_k_sub[j][i]][h].time_unix) - parseInt(wholeData[dt_k[j]][i][d_k_sub[j][i]][0].time_unix));
                                    k++;
                                }
                                if (acum_area == 0 && !ConfigJson[variable]['accumulated_graph'] && parseFloat(wholeData[dt_k[j]][i][d_k_sub[j][i]][0][variable])) { // Only one bin is available
                                    averages[k] = parseFloat(wholeData[dt_k[j]][i][d_k_sub[j][i]][0][variable]);
                                    k++;
                                }
                            }
                        }
                    }
                }
            }
        }
        var average  = averages.reduce(function(a, b) { return a + b; }, 0) / averages.length;

        // Get containers colors
        const configuration = ConfigJson[variable];
        
        // CSS
        const ParentContainer = styled.div`
            margin: 0 auto;
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 14px 0px 10px 0px;
            position: relative;
            width: 100%;

            background-color: #dedede;

            display: flex;
            justify-content: space-around;
            color: black;
            font-size: 16px;
            list-style: none;

            z-index: 1;
            line-height: 11pt;
        `
       
        if (min === 100000) {min = "Lack of data"};
        if (!average) {average = "Lack of data"};
        if (max === 0) {max = "Lack of data"};


        return (
            <ParentContainer>
                <li><MinContainer variable={variable} value={min} timestamp={time_min} configuration={configuration} >  </MinContainer></li>
                <li><AverageContainer variable={variable} value={average} configuration={configuration} >  </AverageContainer></li>
                <li><MaxContainer variable={variable} value={max} timestamp={time_max} configuration={configuration} >  </MaxContainer></li>
            </ParentContainer>
        ); 
    }
    else {
        return null;
    }

}
 
export default DataContainers;