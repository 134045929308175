import React, {useState, useContext, useEffect} from 'react';
import styled from '@emotion/styled'

// import TopFilter from './components/Layout/top_filter'
import GraphSectionMap from './../Map/graph_section_map'
import GraphSection from './graph_section'
import ButtonDownloadExcel from './buttons/ButtonDownloadExcel';
import ButtonDownloadPDF from './buttons/ButtonDownloadPDF';


import Widget from './devices_filter/checkbox.js';
import { ContextDt } from './devices_filter/Context_dt';
import { ContextD } from './devices_filter/Context_d';


const Content = (props) => {

    const Button = styled.div ` 
        background-color:#ffffff;
        margin: 0 auto;
        margin-left: 5pt;
    `

    const SectionHeader = styled.div ` 
        background-color: #394475;

        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
        list-style: none;
        line-height: 20px;

        padding: 3px 10px 3px 10px;
        border-radius: 0pt;
        border-color: #000000;
        border-width: 0pt;
        border-style: solid;

        position: sticky;
        margin: 0 auto;
        vertical-align: middle;
        top: 55px;

        z-index: 102;
    `

    const Section = styled.div`
        width: 55%; /* width of the content of the whole page */
        margin: 0 auto;
        margin-bottom: 40px;
    `

    const SectionD = styled.div`
        width: 100%; /* width of the content of the whole page */
        margin: 0 auto;
        margin-bottom: 5px;
    `

    const MarginHeader = styled.div ` 
        margin-top: 4pt;
        line-height: 20px;
        color: white;
    `


    const [context_dt] = useContext(ContextDt);
    const [context_d] = useContext(ContextD);

    const offset_desired = React.useRef();
    const [FilterOffset, setFilterOffset] = useState(31556926);

    const [wholeDataOrdered, setWholeDataOrdered] = useState(null);
    const [wholeDataFiltered, setWholeDataFiltered] = useState(null);

    const [boolShowFilter, setBoolShowFilter] = useState(true);
    
    var dt_k = JSON.parse(props.dt_k);
    var d_k_sub = JSON.parse(props.d_k_sub);

    if (props.wholeDataOriginal && wholeDataOrdered == null) {
        setWholeDataOrdered(props.wholeDataOriginal); // string
        setWholeDataFiltered(props.wholeDataOriginal); // string
    }

    var dt_k_copy = JSON.parse(props.dt_k); 
    var d_k_sub_copy = JSON.parse(props.d_k_sub); 
    const [dt_k_copy_out, setDt_k_copy_out] = useState(props.dt_k);
    const [d_k_sub_copy_out, setD_k_sub_copy_out] = useState(props.d_k_sub);

    const [variableList, setVariableList] = useState(null); // It will store a list with available variables. Some will need to be plotted


    useEffect(()=>{ // Initialize VariableList
        let wholeJson = JSON.parse(props.wholeDataOriginal);
        var variablesList = [];

        for (let j in dt_k_copy) { // iterate through device types
            for (let i in d_k_sub_copy[j]) { // iterate through devices in each device type
                for (let h in  wholeJson[dt_k_copy[j]][i][d_k_sub_copy[j][i]]) {
                    var aux = variablesList.concat(Object.keys(wholeJson[dt_k_copy[j]][i][d_k_sub_copy[j][i]][h]));
                    variablesList = aux.filter((item, pos) => aux.indexOf(item) === pos);
                }
            }
        }

        setVariableList(variablesList);
    }, []) // Only computed at first iteration


    const clickFilter = () => { // Parent filter
        var offset_filter_aux = parseInt(offset_desired.current.value);
        
        setFilterOffset(offset_filter_aux);
            
        var now = new Date();
        var utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() , 
        now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        var current_utc = utc_timestamp/1000;
        var threshold_utc = current_utc - offset_filter_aux;

        // Delete device types or devices if the filter requires it so
        var wholeJson = JSON.parse(props.wholeDataOriginal);
        var k = 0; 
        var m = 0;
        var l = 0; 
        for (var j in dt_k) { // iterate through device types
            if (context_dt[j] == false) {
                delete wholeJson[dt_k[l]];
                dt_k_copy.splice(l,1);
                d_k_sub_copy.splice(l,1);
            } else {
                m = 0;
                for (var i in d_k_sub[j]) { // iterate through devices in each device type
                    if (context_d[j][i] === false) {
                        wholeJson[dt_k[j]].splice(m,1);
                        d_k_sub_copy[l].splice(m,1);
                    } else {
                        m++;
                    }
                }
                l++;
            }
        }

        // Delete arrays if they are empty, that is, all the devices of a dtype are unmarked
        l = 0;
        if (j == dt_k.length) { // prior loop has finished
            for (j in dt_k_copy) {
                if (wholeJson[dt_k_copy[j]].length === 0) {
                    // console.log("llargdada 0");
                    // console.log(wholeJson[dt_k_copy[l]]);
                    delete wholeJson[dt_k_copy[l]];
                } else {
                    l++;
                }
            }
        }

        // Set data that will be passed to graphs and containers
        setWholeDataOrdered(JSON.stringify(wholeJson));


        // Apply temporal filter for the excel component
        const wholeJson_copy = JSON.parse(JSON.stringify(wholeJson)); // splice reduces length, we cannot splice an object and at the same time set it to our stop condition
        k = 0;


        console.log(offset_filter_aux);
        var variablesList = [];

        for (j in dt_k_copy) { // iterate through device types
            for (i in d_k_sub_copy[j]) { // iterate through devices in each device type
                if (offset_filter_aux == -1) {
                    let found = false;
                    var found_value = null;
                    for (let h = wholeJson_copy[dt_k_copy[j]][i][d_k_sub_copy[j][i]].length - 1; h >= 0; h--) { // iterate through each row of the given device, backwards
                        if (wholeJson[dt_k_copy[j]][i][d_k_sub_copy[j][i]][h] && !found) { 
                            // we will store only the last value, nothing more. Because of this, maybe a gps will be present on the graph but not on the excel.
                            // The problem resides in that the last gps value, for instance, may not be given at the last radiation value, for example
                            found = true;
                            found_value = wholeJson[dt_k_copy[j]][i][d_k_sub_copy[j][i]][h];
                        }
                    }
                    wholeJson[dt_k_copy[j]][i][d_k_sub_copy[j][i]] = []; // clear all the previous information
                    if (found) {
                        wholeJson[dt_k_copy[j]][i][d_k_sub_copy[j][i]][0] = found_value; // if found, store only the found value
                    }
                }

                else {
                    for (var h = 0 ; h < wholeJson_copy[dt_k_copy[j]][i][d_k_sub_copy[j][i]].length; h++) { // iterate through each row of the given device
                        var timeUnix = wholeJson[dt_k_copy[j]][i][d_k_sub_copy[j][i]][k].time_unix;
                        if (timeUnix < threshold_utc) {
                            wholeJson[dt_k_copy[j]][i][d_k_sub_copy[j][i]].splice(k,1);
                        } else {  // Recompute the variables list. If a certain variable is not find in any of the selected devices, it shouldn't be shown
                            var aux = variablesList.concat(Object.keys(wholeJson[dt_k_copy[j]][i][d_k_sub_copy[j][i]][k]));
                            variablesList = aux.filter((item, pos) => aux.indexOf(item) === pos);

                            k++;
                        }
                    }
                }
                k=0;
            }
        }

        if (offset_filter_aux != -1) {
            // Set the VariableList, in which we store the current values of our data object; some will need to be plotted
            setVariableList(variablesList);
        }


        // Set device type and device keys
        setDt_k_copy_out(JSON.stringify(dt_k_copy));
        setD_k_sub_copy_out(JSON.stringify(d_k_sub_copy));

        // Set data that will be passed to the excel component
        setWholeDataFiltered(JSON.stringify(wholeJson));

    }


    const ToggleFilter = () => {
        setBoolShowFilter(!boolShowFilter);
    }


    return ( 
        <Section>

                
            <SectionHeader>
                    <li> 
                        <MarginHeader>
                            {"Devices"}
                            {props.variable}
                            <Button onClick={() => {ToggleFilter()}} className="generalbutton" > Toggle  </Button>
                        </MarginHeader>
                    </li>

                    <li>
                        <MarginHeader>
                            Temporal filter
                            <select ref={offset_desired}  defaultValue={FilterOffset} className="generalbutton" >
                                <option value="31556926"> ALL </option>
                                <option value="-1"> LAST </option>
                                <option value="86400"> 24 hours </option>
                                <option value="172800"> 48 hours </option>
                                <option value="604800"> 7 days </option>
                                <option value="2592000"> 1 month </option>
                                <option value="31556926"> 1 year </option>
                            </select>
                            <Button onClick={clickFilter} className="generalbutton"> Apply </Button>
                        </MarginHeader>
                    </li>

                <li>
                        <ButtonDownloadPDF variable={"All"}/>
                        <ButtonDownloadExcel variable={"All"} wholeData={wholeDataFiltered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out} /> 
                </li>
            </SectionHeader>
                    
            {boolShowFilter && wholeDataOrdered ? (
                <SectionD>
                    {dt_k && d_k_sub ? ( 
                        <Widget dt_k={dt_k} d_k_sub={d_k_sub} />
                     ) : (null)}
                </SectionD>
            ) : (null)}

            {variableList && FilterOffset ? (
                <div id={"All"}>

                    {true ? ( <GraphSectionMap variable={"GPS"} offset={FilterOffset} wholeData={wholeDataOrdered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out} dC={props.dC}  /> ) : (null)}

                    {variableList.includes("temperature") ? ( <GraphSection variable={"Temperature"} offset={FilterOffset} wholeData={wholeDataOrdered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out} /> ) : (null)}
                    {variableList.includes("humidity") ? ( <GraphSection variable={"Humidity"} offset={FilterOffset} wholeData={wholeDataOrdered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out}  /> ) : (null)}
                    {/* {variableList.includes("rain") ? ( <GraphSection variable={'rain'[0].toUpperCase() + 'rain'.substring(1)} offset={FilterOffset} wholeData={wholeDataOrdered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out} context={context_dt} /> ) : (null)} */}
                    {variableList.includes("rain") ? ( <GraphSection variable={"Rain"} offset={FilterOffset} wholeData={wholeDataOrdered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out}  /> ) : (null)}

                    {variableList.includes("radiation") ? ( <GraphSection variable={"Radiation"} offset={FilterOffset} wholeData={wholeDataOrdered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out}  /> ) : (null)}
                    {variableList.includes("leafwetness")? ( <GraphSection variable={"Leafwetness"} offset={FilterOffset} wholeData={wholeDataOrdered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out}  /> ) : (null)}
                    {variableList.includes("moisture") ? ( <GraphSection variable={"Moisture"} offset={FilterOffset} wholeData={wholeDataOrdered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out}  /> ) : (null)}

                    {variableList.includes("wind") ? ( <GraphSection variable={"Wind"} offset={FilterOffset} wholeData={wholeDataOrdered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out}  /> ) : (null)}

                    {variableList.includes("battery") ? ( <GraphSection variable={'battery'[0].toUpperCase() + 'battery'.substring(1)} offset={FilterOffset} wholeData={wholeDataOrdered} dt_k={dt_k_copy_out} d_k_sub={d_k_sub_copy_out}  /> ) : (null)}

                </div>
            ) : (null)}

        </Section>
     );
}
 
export default Content;
