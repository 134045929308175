
import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled'
import { AccountContext } from './Accounts';

import UserPool from './UserPool';
import { UserContext } from './context';
import Cookies from "js-cookie";
// import ForgotPassword from './forgot_password';
import "./auth.css";
// Import the AWS SDK
const AWS = require('aws-sdk');

// Set up AWS SES
const ses = new AWS.SES({
  region: 'eu-west-1', // Replace with your AWS region
})
export default () => {
    const SignupMessage = styled.div`
    padding-bottom: 20pt;
    font-size: 10pt;
    text-align: center;
    margin: 10px auto;
    color: black; 
`

const AuxSignupMessage = styled.div`
    padding-bottom: 20pt;
    font-size: 10pt;
    text-align: center;
    margin: 10px auto;
    color: black; 
`
const AuthLabel = styled.label`
    color: black; 
`;
    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    let emailLoginId = React.createRef();
    let passwordLoginId = React.createRef();

    const [emailSignUp, setEmailSignUp] = useState('');
    const [passwordSignUp, setPasswordSignUp] = useState('');
    const [nom, setName] = useState('');
    const [cognoms, setSurnames] = useState('');
    const [nomEmpresa, setCompany] = useState('');
    const [adrecaEmpresa, setAddress] = useState('');
    const [ciutat, setCity] = useState('');
    const [carrecPersona, setJobTitle] = useState('');
    let carrecPersonaId = React.createRef();
    let ciutatId = React.createRef();
    let adrecaEmpresaId = React.createRef();
    let nomEmpresaId = React.createRef();
    let nomId = React.createRef(); 
    let cognomsId = React.createRef();
    let emailSignUpId = React.createRef();
    let passwordSignUpId = React.createRef();
    
    const { authenticate } = useContext(AccountContext);
    const { getSession } = useContext(AccountContext);

    // usecontext, that is importantgffjgh
    const {isUserLogged, setIsUserLogged, userEmail, setUserEmail} = useContext(UserContext); // ! 
    // const [userEmail, setUserEmail] = useState(''); // to check if the whole webpage should be shown or not
// prova
    const [signupMessage, setSignupMessage] = useState();
    const [loginMessage, setLoginMessage] = useState();
    useEffect(()=>{
        if (emailLogin) { 
            authenticate(emailLogin, passwordLogin)
            .then(data => {
                console.log('Logged in!', data);
                setIsUserLogged(true);
                getSession().then(({ user, email, attributes}) => {
                    setUserEmail(email);
                    // console.log("Session: ", user);
                    // console.log("Session email: ", email);
                    // console.log("att: ", attributes);
                });
            })
            .catch(err => {
                console.error('Failed to login!', err);
                setLoginMessage(err.message);
            })

        }
    }, [passwordLogin]);


    useEffect(() => {
        const idTokenFromCookie = Cookies.get("idToken");
        if (idTokenFromCookie) {
          // Directly set the user as logged in and fetch session data
          console.log('token', idTokenFromCookie);
          setIsUserLogged(true);
          getSession().then(({ email }) => {
            setUserEmail(email);
          });
        }
      }, []); // The empty dependency array ensures this useEffect runs only once
    

    const onLogin = event => {
        event.preventDefault();
        setEmailLogin(emailLoginId.current.value);
        setPasswordLogin(passwordLoginId.current.value);
    };


    useEffect(()=>{
        if (emailSignUp) {
            const attributes = [
                { Name: 'custom:nom', Value: nom },
                { Name: 'custom:cognom', Value: cognoms },
                { Name: 'custom:adreça_empresa', Value: adrecaEmpresa },
                { Name: 'custom:nom_empresa', Value: nomEmpresa },
                { Name: 'custom:ciutat', Value: ciutat },
                { Name: 'custom:càrrec_persona', Value: carrecPersona },
            ]; 
            UserPool.signUp(emailSignUp, passwordSignUp, attributes, null, (err, data) => {
                if(err){
                    if (err.code === 'InvalidPasswordException') {
                        // For any other password policy violation not explicitly handled
                        setSignupMessage('Password does not meet the required criteria.1 número, 1 carácter especial, una letra mayúscula, una letra minúscula');
                        console.log('error contra');
                    }
                    else {
                        console.log('Error signing up: ', err);
                        setSignupMessage('an error ocurred while signing up.')
                    }
                }else{
                    console.log('Signup successful:', data);
                    setSignupMessage('A signup request has been made, please verify your account. Your user account needs approval from the administrator within a maximum period of 48 hours.');

                    // Send an email to the administrator
                    sendAdminNotification(emailSignUp, nom, cognoms, nomEmpresa, adrecaEmpresa, ciutat, carrecPersona);    
                }
            });
        }
    }, [passwordSignUp]);

    const onSignUp = (event) => {
        event.preventDefault();
    
        // Check if required fields have values
        if (
            emailSignUpId.current.value &&
            passwordSignUpId.current.value &&
            nomId.current.value &&
            cognomsId.current.value &&
            nomEmpresaId.current.value &&
            adrecaEmpresaId.current.value &&
            ciutatId.current.value &&
            carrecPersonaId.current.value
        ) {
            // If all required fields have values, proceed with sign-up logic
            setEmailSignUp(emailSignUpId.current.value);
            setPasswordSignUp(passwordSignUpId.current.value);
            setName(nomId.current.value);
            setSurnames(cognomsId.current.value);
            setCompany(nomEmpresaId.current.value);
            setAddress(adrecaEmpresaId.current.value);
            setCity(ciutatId.current.value);
            setJobTitle(carrecPersonaId.current.value);
    
            // ... continue the sign-up process
        } else {
            // If any required field is empty, prevent the sign-up and prompt the user
            alert('Please fill in all required fields.');
        }
    };


    // Function to send an email notification to the administrator
const sendAdminNotification = (userEmail, nom, cognoms, nomEmpresa, adrecaEmpresa, ciutat, carrecPersona) => {
  const params = {
    Destination: {
      ToAddresses: ['sternasender1@gmail.com','it@sterna.es'], // Replace with your administrator's email address
    },
    Message: {
      Body: {
        Text: {
          Data: `A new user has registered.\nEmail: ${userEmail}\nName: ${nom}\nSurnames: ${cognoms}\nCompany: ${nomEmpresa}\nAddress: ${adrecaEmpresa}\nCity: ${ciutat}\nJob Title: ${carrecPersona}`,
        },
      },
      Subject: {
        Data: 'New User Registration',
      },
    },
    Source: 'sternasender1@gmail.com', // Replace with your SES verified sender email address
  };

  ses.sendEmail(params, (err, data) => {
    if (err) {
      console.error('Error sending email notification to administrator:', err);
    } else {
      console.log('Email notification sent successfully:', data);
    }
  });
};
    return (
        <div>
            <div className="header-container">
                <header className="auth-app-header">
                    <div className="auth-logo-es">
                        <img src={"/imgs/logo-sterna-st.png"} alt="Logo" />
                        <h1 className="espot">
                            <span>eSpot Dashboard</span>
                        </h1>   
                    </div>
                </header>
            </div>

            <div>
                <div>
                    <form>
                        <AuthLabel>Sign up</AuthLabel>
                        {signupMessage ? null : (
                            <SignupMessage>Password must contain at least 8 characters including 1 uppercase, 1 number and 1 special character.</SignupMessage>
                        )}
                        <input defaultValue="address@mail.com" ref={emailSignUpId} type="text" name="email" placeholder="Email" required />
                        <input  defaultValue="passwordA1!" ref={passwordSignUpId} type="password" name="pswd" placeholder="Password" required />
                        <input ref={nomId} type="text" name="name" placeholder="Name" required />
                        <input ref={cognomsId} type="text" name="surnames" placeholder="Surnames" required />
                        <input ref={nomEmpresaId} type="text" name="company" placeholder="Company" required />
                        <input ref={adrecaEmpresaId} type="text" name="address" placeholder="Address" required />
                        <input ref={ciutatId} type="text" name="city" placeholder="City" required />
                        <input ref={carrecPersonaId} type="text" name="jobTitle" placeholder="Job Title" required />            
                        <button className="button-auth" onClick={onSignUp}>Sign up</button>        
                    </form>
                        {signupMessage ? (
                            <AuxSignupMessage style={{ color: 'black' }}> {signupMessage} </AuxSignupMessage>
                        ) : (null)}
                </div>
                <div>
                    <form>
                        <AuthLabel>Log in</AuthLabel>
                        <input  defaultValue="address@mail.com" ref={emailLoginId} type="text"  name="email" placeholder="Email" required />
                        <input  defaultValue="password" ref={passwordLoginId} type="password" name="pswd" placeholder="Password" required />
                        <button className="button-auth" onClick={onLogin}>Log in</button>
                    </form>
                </div>
            </div>
        </div>

    );

};



/*import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled'
import { AccountContext } from './Accounts';

import UserPool from './UserPool';
import { UserContext } from './context';
import Cookies from "js-cookie";
// import ForgotPassword from './forgot_password';
import "./auth.css";

export default () => {
    const SignupMessage = styled.div`
        padding-bottom: 20pt;
        font-size: 10pt;
        text-align: center;
        margin: 10px auto;
        color: white;
    `

    const AuxSignupMessage = styled.div`
        padding-bottom: 20pt;
        font-size: 10pt;
        text-align: center;
        margin: 10px auto;
        color: white;

    `
  
    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    let emailLoginId = React.createRef();
    let passwordLoginId = React.createRef();

    const [emailSignUp, setEmailSignUp] = useState('');
    const [passwordSignUp, setPasswordSignUp] = useState('');
    const [nom, setName] = useState('');
    const [cognoms, setSurnames] = useState('');
    const [nomEmpresa, setCompany] = useState('');
    const [adrecaEmpresa, setAddress] = useState('');
    const [ciutat, setCity] = useState('');
    const [carrecPersona, setJobTitle] = useState('');
    let carrecPersonaId = React.createRef();
    let ciutatId = React.createRef();
    let adrecaEmpresaId = React.createRef();
    let nomEmpresaId = React.createRef();
    let nomId = React.createRef(); 
    let cognomsId = React.createRef();
    let emailSignUpId = React.createRef();
    let passwordSignUpId = React.createRef();
    
    const { authenticate } = useContext(AccountContext);
    const { getSession } = useContext(AccountContext);

    // usecontext, that is importantgffjgh
    const {isUserLogged, setIsUserLogged, userEmail, setUserEmail} = useContext(UserContext); // ! 
    // const [userEmail, setUserEmail] = useState(''); // to check if the whole webpage should be shown or not
// prova
    const [signupMessage, setSignupMessage] = useState();
    const [loginMessage, setLoginMessage] = useState();
    useEffect(()=>{
        if (emailLogin) { 
            authenticate(emailLogin, passwordLogin)
            .then(data => {
                // console.log('Logged in!', data);
                setIsUserLogged(true);
                getSession().then(({ user, email, attributes}) => {
                    setUserEmail(email);
                    // console.log("Session: ", user);
                    // console.log("Session email: ", email);
                    // console.log("att: ", attributes);
                });
            })
            .catch(err => {
                console.error('Failed to login!', err);
                setLoginMessage(err.message);
            })

        }
    }, [passwordLogin]);


    useEffect(() => {
        const idTokenFromCookie = Cookies.get("idToken");
        if (idTokenFromCookie) {
          // Directly set the user as logged in and fetch session data
          console.log('token', idTokenFromCookie);
          setIsUserLogged(true);
          getSession().then(({ email }) => {
            setUserEmail(email);
          });
        }
      }, []); // The empty dependency array ensures this useEffect runs only once
    

    const onLogin = event => {
        event.preventDefault();
        setEmailLogin(emailLoginId.current.value);
        setPasswordLogin(passwordLoginId.current.value);
    };


    useEffect(()=>{
        if (emailSignUp) {
            const attributes = [
                { Name: 'custom:nom', Value: nom },
                { Name: 'custom:cognom', Value: cognoms },
                { Name: 'custom:adreça_empresa', Value: adrecaEmpresa },
                { Name: 'custom:nom_empresa', Value: nomEmpresa },
                { Name: 'custom:ciutat', Value: ciutat },
                { Name: 'custom:càrrec_persona', Value: carrecPersona },
            ];  
            UserPool.signUp(emailSignUp, passwordSignUp, attributes, null, (err, data) => {
                if (err.code === 'InvalidPasswordException') {
                    // For any other password policy violation not explicitly handled
                    setSignupMessage('Password does not meet the required criteria.1 número, 1 carácter especial, una letra mayúscula, una letra minúscula');
                    console.log('error contra');
                }
                else {
                    console.log(data);
                    setSignupMessage('A signup request has been made. We will process your account soon.')
                }
            });
        }
    }, [passwordSignUp]);

    const onSignUp = (event) => {
        event.preventDefault();
    
        // Check if required fields have values
        if (
            emailSignUpId.current.value &&
            passwordSignUpId.current.value &&
            nomId.current.value &&
            cognomsId.current.value &&
            nomEmpresaId.current.value &&
            adrecaEmpresaId.current.value &&
            ciutatId.current.value &&
            carrecPersonaId.current.value
        ) {
            // If all required fields have values, proceed with sign-up logic
            setEmailSignUp(emailSignUpId.current.value);
            setPasswordSignUp(passwordSignUpId.current.value);
            setName(nomId.current.value);
            setSurnames(cognomsId.current.value);
            setCompany(nomEmpresaId.current.value);
            setAddress(adrecaEmpresaId.current.value);
            setCity(ciutatId.current.value);
            setJobTitle(carrecPersonaId.current.value);
    
            // ... continue the sign-up process
        } else {
            // If any required field is empty, prevent the sign-up and prompt the user
            alert('Please fill in all required fields.');
        }
    };
    
    return (
        <div>
            <div className="header-container">
                <header className="auth-app-header">
                    <div className="auth-logo-es">
                        <img src={"/imgs/logo-sterna-st.png"} alt="Logo" />
                        <h1 className="espot">
                            <span>eSpot Dashboard</span>
                        </h1>   
                    </div>
                </header>
            </div>

            <div>
                <div>
                    <form>
                        <label>Sign up</label>
                        {signupMessage ? null : (
                            <SignupMessage>Password must contain at least 8 characters including 1 uppercase, 1 number and 1 special character.</SignupMessage>
                        )}
                        <input defaultValue="address@mail.com" ref={emailSignUpId} type="text" name="email" placeholder="Email" required />
                        <input  defaultValue="passwordA1!" ref={passwordSignUpId} type="password" name="pswd" placeholder="Password" required />
                        <input ref={nomId} type="text" name="name" placeholder="Name" required />
                        <input ref={cognomsId} type="text" name="surnames" placeholder="Surnames" required />
                        <input ref={nomEmpresaId} type="text" name="company" placeholder="Company" required />
                        <input ref={adrecaEmpresaId} type="text" name="address" placeholder="Address" required />
                        <input ref={ciutatId} type="text" name="city" placeholder="City" required />
                        <input ref={carrecPersonaId} type="text" name="jobTitle" placeholder="Job Title" required />            
                        <button className="button-auth" onClick={onSignUp}>Sign up</button>        
                    </form>
                        {signupMessage ? (
                            <AuxSignupMessage style={{ color: 'black' }}> {signupMessage} </AuxSignupMessage>
                        ) : (null)}
                </div>
                <div>
                    <form>
                        <label>Log in</label>
                        <input  defaultValue="address@mail.com" ref={emailLoginId} type="text"  name="email" placeholder="Email" required />
                        <input  defaultValue="password" ref={passwordLoginId} type="password" name="pswd" placeholder="Password" required />
                        <button className="button-auth" onClick={onLogin}>Log in</button>
                    </form>
                </div>
            </div>
        </div>

    );

};*/