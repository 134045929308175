import React from 'react'
import styled from '@emotion/styled'

import AverageContainer from './data_blocks/average' 
import MaxContainer from './data_blocks/max'
import MinContainer from './data_blocks/min'

import ConfigJson from './../../utils/loadjson';

 
function unixTime(unixtime) {

    var u = new Date(unixtime*1000);

      return u.getFullYear() +
        '-' + ('0' + (u.getMonth()+1)).slice(-2) +
        '-' + ('0' + u.getDate()).slice(-2) + 
        ' ' + ('0' + u.getHours()).slice(-2) +
        ':' + ('0' + u.getMinutes()).slice(-2) +
        ':' + ('0' + u.getSeconds()).slice(-2)
};
 
 
const DataContainers = (props) => {
    var variable = props.variable;
    var dataFiltered = props.dataFiltered;

    var min = 1000000; 
	var time_min = "NaN";
    var max = -1000000; 
	var time_max = "NaN";
	var sum = 0;
	var n = 0;

    if (variable) {
        if (dataFiltered) {
			var devices = Object.keys(dataFiltered);
            for (var i in devices){
				var k = 0;
				var first_found = false;
				for (var j in dataFiltered[devices[i]]){
					var value = parseFloat(dataFiltered[devices[i]][j][variable]);
					if (!isNaN(value)){
						if (value > max){
							max = value;
							time_max = unixTime(dataFiltered[devices[i]][j]['Timestamp']);
						}
						if (value < min){
							min = value;
							time_min = unixTime(dataFiltered[devices[i]][j]['Timestamp']);
						}
						if (ConfigJson[variable]['accumulated_average']){
							if (first_found){
								var value_ant = parseFloat(dataFiltered[devices[i]][k][variable]);
								var time = parseInt(dataFiltered[devices[i]][j]['Timestamp']);
								var time_ant = parseInt(dataFiltered[devices[i]][k]['Timestamp']);
								sum += ((value + value_ant) / 2) * (time - time_ant);
								n += time - time_ant;
								k = j;
							}
							else{
								sum += value;
								n++;
								first_found = true;
								k = j;
							}
						}
						else{
							sum += value;
							n++;
						}
					}
				}
			}
        }

        // Get containers colors
        const configuration = ConfigJson[variable];
        
        // CSS
        const ParentContainer = styled.div`
            margin: 0 auto;
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 14px 0px 10px 0px;
            position: relative;
            width: 100%;

            background-color: white;

            display: flex;
            justify-content: space-around;
            color: black;
            font-size: 16px;
            list-style: none;

            z-index: 1;
            line-height: 11pt;
        `
       
        if (min === 1000000) {min = "Lack of data"};
        if (max === -1000000) {max = "Lack of data"};
		if (sum == 0 && n > 0) var average = 0;
		else if (n == 0) var average = "Lack of data";
		else var average = sum/n;

        return (
            <ParentContainer>
                <li><MinContainer variable={variable} value={min} timestamp={time_min} configuration={configuration} >  </MinContainer></li>
                <li><AverageContainer variable={variable} value={average} configuration={configuration} >  </AverageContainer></li>
                <li><MaxContainer variable={variable} value={max} timestamp={time_max} configuration={configuration} >  </MaxContainer></li>
            </ParentContainer>
        ); 
    }
    else {
        return null;
    }

}
 
export default DataContainers;