import React from 'react';
import styled from '@emotion/styled'

import Chart from './chart'

import ConfigJson from './../../utils/loadjson'; // configuration file


const ChartSummoner = (props) => {
    var variable = props.variable.toLowerCase(); 

    // var ordenat = JSON.parse(props.wholeData);
    var wholeData = JSON.parse(props.wholeData);

    var dt_k = props.dt_k;
    var d_k_sub = props.d_k_sub;
    var dataMatrix = [];
    var k = 0;

    if (wholeData) {
        var device_list = []; // contains a list of non-repeated devices

        for (var j in dt_k) { // iterate through device types
            for (var i in d_k_sub[j]) { // iterate through devices in each device type
    
                // check if device exists, in order to avoid repetition
                var is_repeated = 0;
                for (var z in device_list){
                    if (d_k_sub[j][i][0] == device_list[z]) { // device already exists
                        is_repeated++;
                    }
                }
                
                // is_repeated=0;
                if (is_repeated == 0) { // device doesn't exist is dataMatrix
                    device_list.push(d_k_sub[j][i]);
    
                    dataMatrix[k] = [];
                    dataMatrix[k]['time'] = [];
                    dataMatrix[k][variable] = [];
                    dataMatrix[k]['device'] = d_k_sub[j][i];
    
                    var l = 0; 
                    for (var h in wholeData[dt_k[j]][i][d_k_sub[j][i]]) { // iterate through each row of the given device
                        if ((wholeData[dt_k[j]][i][d_k_sub[j][i]][h][variable])){ // filter, maybe a GPS bin has sneaked in
                            dataMatrix[k]['time'][l] = wholeData[dt_k[j]][i][d_k_sub[j][i]][h].time;
                            dataMatrix[k][variable][l] = wholeData[dt_k[j]][i][d_k_sub[j][i]][h][variable];
                            l++;
                        }

                    }
                    k++;
                }
            }
        }
    }


        const GraphContainer = styled.div`
            margin: 0 auto;
            /* padding-top: 4px; */
            padding-bottom: 1.5px;
            background-color: #dedede;

            /* min-width: 50; */
            /* max-width: 900pt; */
            width: 100%;
            align-items: center;
            border-radius: 0px;
        `
    
    return ( 
        <GraphContainer>
            {dataMatrix ? (
                <Chart  variable={props.variable} config={ConfigJson} dataMatrix={dataMatrix} />   
            ) : (null)}
        </GraphContainer>
    );
}
 
export default ChartSummoner;