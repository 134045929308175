import React from 'react';
import styled from '@emotion/styled'
import { Chart } from "react-google-charts";

const chart_options = {
						isStacked:true,
						legend: { position: 'none' },
						bar: { groupWidth: "75%" },
						colors: ['blue'],
						chartArea: { width: '90%' },
						backgroundColor: '#ffffff',
					  }

const Precontent = (props) => {
	
	const SectionBar = styled.div ` 
        background-color: white;
		padding: 10px 20px 10px 20px;
		border-color: #000000;
		border-width: 1pt;
		border-style: solid;
		margin: 0 auto;
		vertical-align: middle;
        color: black;
        border-radius: 0pt;
		align-items: center;
    `
	
	const SectionHeader = styled.div ` 
		background-color: #394475;

		display: flex;
		justify-content: space-between;
		color: black;
		font-size: 20px;
		list-style: none;
		line-height: 20px;

		padding: 3px 10px 3px 10px;
		border-radius: 0pt;
		border-color: #000000;
		border-width: 0pt;
		border-style: solid;
		top: 50%;

		margin: 0 auto;
		vertical-align: middle;
    `
	
	const MarginHeader = styled.div ` 
        margin-top: 4pt;
		margin-bottom: 4pt;
        line-height: 20px;
        color: white;
    `
	
	const Section = styled.div ` 
        /* background-color: #e8e8e8, */
        margin-bottom: 20pt;
    `
	
	var Centrals = props.centrals;
	var Peripherals = props.peripherals;
	var peripheral_central = props.centralPeripherals;
	var devNames = props.devNames;
	let chart_ready = false;
	if (Centrals.length>0 && Peripherals.length>0 && Object.keys(peripheral_central).length>0 && Object.keys(devNames).length>0){
		var devices = [];
		for (let i=0; i<Peripherals.length; i++){
			devices[i] = devNames[Peripherals[i]];
		}
		var gateways = [];
		for (let i=0; i<Centrals.length; i++){
			gateways[i] = devNames[Centrals[i]];
		}
		var chart_data = [];
		chart_data[0] = ['Central'].concat(devices);
		for (let i=0; i<Centrals.length; i++){
			var row = [];
			row.push(gateways[i]);
			for (let j=0; j<Peripherals.length; j++){
				if(peripheral_central[Centrals[i]].includes(Peripherals[j])) row.push(1);
				else row.push(0);
			}
			chart_data.push(row);
		}
		chart_ready = true;
	}

    return ( 
		<Section>
			<SectionHeader>
				<MarginHeader>
					{"Last Message Location"}
				</MarginHeader>
			</SectionHeader>
			<SectionBar>
				{chart_ready ? (
					<Chart
						chartType="ColumnChart"
						data = {chart_data}
						options = {chart_options}
					/>
				):(
					<div>Charging...</div>
				)}
			</SectionBar>
		</Section>
     );
}
 
export default Precontent;