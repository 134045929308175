import React, { useState, useContext} from 'react';
import styled from '@emotion/styled'
import './choice_menu.css';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { SelectContext } from './context';
import Precontent from '../Layout/precontent';

const Selection = props => {

	const Button = styled.div ` 
        background-color:#ffffff;
        margin: 0 auto;
    `
	
	const ButtonSection = styled.div ` 
        margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 25px 5px 10px 5px;
    `
	
	const Section = styled.div`
        width: 70%; /* width of the content of the whole page */
        margin: 0 auto;
        margin-bottom: 150px;
    `
	
	const CheckboxsContainer = styled.div ` 
        background-color: #dddddd;

        justify-content: space-around;
        color: black;
        font-size: 16px;
        list-style: none;
        line-height: 20px;

        padding: 5px 10px 5px 10px;
        border-radius: 0pt;
        border-color: #394475;
        border-width: 0.9pt;
        border-style: solid;

        margin: 0 auto;
        border-top: 10px;
        top: 80px;

        z-index: 102;
    `
	
	const SectionHeader = styled.div ` 
		background-color: #394475;

		display: flex;
		justify-content: space-between;
		color: black;
		font-size: 20px;
		list-style: none;
		line-height: 20px;

		padding: 3px 10px 3px 10px;
		border-radius: 0pt;
		border-color: #000000;
		border-width: 0pt;
		border-style: solid;
		top: 50%;

		margin: 0 auto;
		vertical-align: middle;
    `
	
	const MarginHeader = styled.div ` 
        margin-top: 4pt;
		margin-bottom: 4pt;
        line-height: 20px;
        color: white;
    `
	  
	// selection contexts
	const {isChoiceSubmitted, setIsChoiceSubmitted, selectedDevices, setSelectedDevices, isCheckAll, setIsCheckAll} = useContext(SelectContext);
	
	// devices lists
	const centrals = props.centrals;
	const peripherals = props.peripherals;
	const centralPeripherals = props.centralPeripherals;
	const devNames = props.devNames;
  
	const clickAction = () => {
		setIsChoiceSubmitted(true);
	}
	
	const fClick = (central, peripheral) => {
		var devicesSel = selectedDevices;
		if (devicesSel.includes(peripheral)){
			devicesSel.splice(devicesSel.indexOf(peripheral), 1);
		}
		else {
			devicesSel.push(peripheral);
		}
		setSelectedDevices(devicesSel);
	}
	
	const selectAll = () => {
		setIsCheckAll(!isCheckAll);
		if (isCheckAll){
			setSelectedDevices([]);
		}
		else{
			var devicesSel = []; 
			for (let i in peripherals){
				devicesSel.push(peripherals[i]);
			}
			setSelectedDevices(devicesSel);
		}
	}
	
	const DevicesList = () => {
		var llista_master = [];
		var CPmatrix = [];
		var n = 0;
		for (var central in centralPeripherals){
			CPmatrix[n] = [];
			for (var i=0; i<centralPeripherals[central].length; i++){
				CPmatrix[n][i]=centralPeripherals[central][i];
			}
			n++;
		}
		CPmatrix.map( (submatrix, i) => {
			llista_master.push((<dt key={centrals[i]}>{devNames[centrals[i]]+":"}</dt>));
			llista_master.push((<dt key={centrals[i] +"_gap"}></dt>));
			submatrix.map( (Peripheral, j) => {
				llista_master.push((<dd key={Peripheral}> <input onChange={() => fClick(centrals[i], Peripheral)} type="checkbox" defaultChecked={selectedDevices.includes(Peripheral)}/> {devNames[Peripheral]} </dd>));
				return null;
			})
			return null;
		})
		return llista_master;
	}

	return (
		<Section>
			<Precontent centrals={centrals} peripherals={peripherals} centralPeripherals={centralPeripherals} devNames={devNames}/>
			<SectionHeader>
				<MarginHeader>
					{"Device filter"}
				</MarginHeader>
			</SectionHeader>
			<CheckboxsContainer>
				<div>
					<dl>
						<dt />
						<dd> <input onChange={() => selectAll()} type="checkbox" defaultChecked={isCheckAll} /> {"SELECT ALL"} </dd>
						<DevicesList />
					</dl>
				</div>
				<ButtonSection>
					<Button onClick={clickAction} className="generalbutton"> Submit </Button>
				</ButtonSection>
			</CheckboxsContainer>
		</Section>
	);
};

export default Selection;
