import React, {useContext, useReducer} from 'react';
import styled from '@emotion/styled';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { ContextDt } from "./Context_dt";
import { ContextD } from "./Context_d";
  

const Widget = ( (props) => {

    const [context_dt, setContext_dt] = useContext(ContextDt);
    const [context_d, setContext_d] = useContext(ContextD);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const CheckboxsContainer = styled.div ` 
        background-color: #c7c7c7;

        display: flex;
        justify-content: space-around;
        color: black;
        font-size: 16px;
        list-style: none;
        line-height: 20px;

        padding: 5px 10px 5px 10px;
        border-radius: 0pt;
        border-color: #394475;
        border-width: 0.9pt;
        border-style: solid;

        margin: 0 auto;
        border-top: 10px;
        top: 80px;

        z-index: 102;
    `



    var dt_k = props.dt_k; // get DeviceTypes
    var d_k_sub = props.d_k_sub; // get Devices

    // Initialize devices to true, and as a matrix
    var dt_k_bools = [];
    var d_k_bools = [];
    if (dt_k && d_k_sub) {
        for (var i in dt_k) {
            dt_k_bools[i] = false;

            d_k_bools[i] = []; // Initialize device boolean matrix
            for (var j in d_k_sub[i]) { // Iterate through devices
                    d_k_bools[i][j] = true;
            }
        }
    }

    // When a device type box is clicked, react
    const fClickDT = (x) => {
        dt_k_bools = context_dt;
        dt_k_bools[x] = !dt_k_bools[x];

        setContext_dt(dt_k_bools);

        forceUpdate();
    }

    const DeviceTypeList = () => {
        if ( context_dt){
            var llista = null;
            var llista_master = [];
            dt_k.map( (dt_k_aux, i) => {
                llista= <ol key={dt_k_aux}>
                            <input type="checkbox" defaultChecked={context_dt[i]} onClick={() => {fClickDT(i)}} />
                            <> {dt_k_aux} </>
                        </ol> ;
                llista_master.push(llista); 
                
                return null;
            });
            return ( <> {llista_master} </> );   
        } 
        else {
            return null;
        }
    }

    // When a device checkbox is clicked, react
    const fClickD = (i, j) => {
        d_k_bools = context_d;
        d_k_bools[i][j] = !d_k_bools[i][j];
        
        setContext_d(d_k_bools);
    }

    const DevicesList = () => {
        var llista = null;
        var llista_master = [];
        if (d_k_sub) {
            d_k_sub.map( (d_k_sub_sub, i) => {
                if (context_dt[i]) { // in order to hide devices when their parent device type is false, avoids bugs
                    d_k_sub_sub.map( (d_k_sub_sub, j) => {
                        llista = (<ol key={d_k_sub_sub.concat(d_k_sub[i])}> <input onChange={() => fClickD(i, j)}  type="checkbox" defaultChecked={context_d[i][j] && context_dt[i]} /> {d_k_sub_sub} </ol>);
                        llista_master.push(llista);

                        return null;
                    })
                }

                return null;
            })
            return ( <> {llista_master} </> );
        }
        else {
            return null;
        }
    }
    

    return (
        <CheckboxsContainer>
            <li> <DeviceTypeList /> </li>
            <li> <DevicesList /> </li>
        </CheckboxsContainer>
    );
}) 



export default Widget;