import React from 'react'
import styled from '@emotion/styled'

import { Scatter } from "react-chartjs-2";



const Chart = (props) => {

    const dataMatrix = props.dataMatrix;
    var variable = props.variable;

    const Graph = styled.div`
        margin: 0 auto;
        margin-top: 0px;
        margin-bottom: 15px;
        padding: 5px 10px 5px 10px;
        background: rgb(255, 255, 255);
        width: 90%;
        height: 100%;
        /* display: flex;  */
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border-radius: 5px;
    `


    const options_temperature = {
        responsive: true,
        mantainAspectRatio: false,
		elements: {
			point: {
				radius: 1
			}
		},
        scales: {
            xAxes: [{
              type: 'time',
              time : {
                displayFormats: {
					'millisecond': 'YYYY/MM/DD - HH:mm:ss',
                    'second': 'YYYY/MM/DD - HH:mm:ss',
                    'minute': 'YYYY/MM/DD - HH:mm:ss',
					'hour': 'YYYY/MM/DD - HH:mm:ss',
                    'day': 'YYYY/MM/DD - HH:mm:ss',
                    'week': 'YYYY/MM/DD - HH:mm:ss',
                    'month': 'YYYY/MM/DD - HH:mm:ss',
                    'quarter': 'YYYY/MM/DD - HH:mm:ss',
                    'year': 'YYYY/MM/DD - HH:mm:ss'
                }
              },
              distribution: 'linear',
              ticks: {
                  maxTicksLimit: 14,
                  fontSize: 11,
              }
            }],
            yAxes: [{
                ticks: {
                    fontSize: 11,
                }
            }]
        },
        legend: {
            labels: {
                fontSize: 11,
                boxWidth: 30
            }
        }
    }
	
	const options_acceleration = {
        responsive: true,
        mantainAspectRatio: false,
		elements:{
			line:{
				stepped: true,
			}
		},
		scales: {
            xAxes: [{
              type: 'time',
              time : {
                displayFormats: {
					'millisecond': 'YYYY/MM/DD - HH:mm:ss',
                    'second': 'YYYY/MM/DD - HH:mm:ss',
                    'minute': 'YYYY/MM/DD - HH:mm:ss',
					'hour': 'YYYY/MM/DD - HH:mm:ss',
                    'day': 'YYYY/MM/DD - HH:mm:ss',
                    'week': 'YYYY/MM/DD - HH:mm:ss',
                    'month': 'YYYY/MM/DD - HH:mm:ss',
                    'quarter': 'YYYY/MM/DD - HH:mm:ss',
                    'year': 'YYYY/MM/DD - HH:mm:ss'
                }
              },
              distribution: 'linear',
              ticks: {
                  maxTicksLimit: 14,
                  fontSize: 11,
              }
            }],
            yAxes: [{
                ticks: {
					min: 0,
                    fontSize: 11,
                }
            }]
        },
        legend: {
            labels: {
                fontSize: 11,
                boxWidth: 30
            }
        }
    }

    const options_battery = { // we want the minumum to be set to 0
        responsive: true,
        mantainAspectRatio: false,
		elements:{
			line:{
				tension: 0,
			}
		},
        scales: {
            xAxes: [{
              type: 'time',
              time : {
                displayFormats: {
                    'millisecond': 'YYYY/MM/DD - HH:mm:ss',
                    'second': 'YYYY/MM/DD - HH:mm:ss',
                    'minute': 'YYYY/MM/DD - HH:mm:ss',
					'hour': 'YYYY/MM/DD - HH:mm:ss',
                    'day': 'YYYY/MM/DD - HH:mm:ss',
                    'week': 'YYYY/MM/DD - HH:mm:ss',
                    'month': 'YYYY/MM/DD - HH:mm:ss',
                    'quarter': 'YYYY/MM/DD - HH:mm:ss',
                    'year': 'YYYY/MM/DD - HH:mm:ss'
                }
              },
              distribution: 'linear',
              ticks: {
                  maxTicksLimit: 14,
                  fontSize: 11,
              }
            }],
            yAxes: [{
                display: true,
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: 100,
                    fontSize: 11,
                }
            }]
        },
        legend: {
            labels: {
                fontSize: 11,
                boxWidth: 30
            }
        }
    }
	
	const options_distance = {
        responsive: true,
        mantainAspectRatio: false,
		elements: {
			point: {
				radius: 1,
			},
			// line: {
				// tension: 0,
			// }
		},
        scales: {
            xAxes: [{
              type: 'time',
              time : {
                displayFormats: {
					'millisecond': 'YYYY/MM/DD - HH:mm:ss',
                    'second': 'YYYY/MM/DD - HH:mm:ss',
                    'minute': 'YYYY/MM/DD - HH:mm:ss',
					'hour': 'YYYY/MM/DD - HH:mm:ss',
                    'day': 'YYYY/MM/DD - HH:mm:ss',
                    'week': 'YYYY/MM/DD - HH:mm:ss',
                    'month': 'YYYY/MM/DD - HH:mm:ss',
                    'quarter': 'YYYY/MM/DD - HH:mm:ss',
                    'year': 'YYYY/MM/DD - HH:mm:ss'
                }
              },
              distribution: 'linear',
              ticks: {
                  maxTicksLimit: 14,
                  fontSize: 11,
              }
            }],
            yAxes: [{
                ticks: {
					min: 0,
                    fontSize: 11,
                }
            }]
        },
        legend: {
            labels: {
                fontSize: 11,
                boxWidth: 30
            }
        }
    }


    // Store all datasets
    var datasets_all = [];
    var colors_vector_back = ["rgba(0,98,255,0.2)","rgba(255,145,0,0.2)","rgba(162,255,0,0.2)","rgba(255,0,0,0.2)","rgba(51,255,189,0.2)","rgba(108,63,0,0.2)","rgba(88,24,69,0.2)", "rgba(255,51,216,0.2)", "rgba(0,108,4,0.2)", "rgba(143,144,0,0.2)", "rgba(144,0,73,0.2)"]
    var colors_vector_border = ["rgba(0,98,255,1)","rgba(255,145,0,1)","rgba(162,255,0,1)","rgba(255,0,0,1)","rgba(51,255,189,1)","rgba(108,63,0,1)","rgba(88,24,69,1)", "rgba(255,51,216,1)", "rgba(0,108,4, 1)", "rgba(143,144,0,1)", "rgba(144,0,74,1)"]

    dataMatrix.map(function (dataMatrix, j) {
        // Create data and time obj, which will be passed to chart.js
        var data_obj = [];
        for (let i = 0; i < dataMatrix.time.length; i++) {
            if (dataMatrix.time[i]) {
				data_obj = data_obj.concat([{x: dataMatrix.time[i], y: parseFloat(dataMatrix[variable][i]).toFixed(3)}]);
				if (variable == 'Acceleration') data_obj = data_obj.concat([{x: dataMatrix.time[i], y: -1}]);
            }
        };

        var back_color;
        var border_color;
        if (j >= colors_vector_back.length){
            let r = Math.floor(Math.random() * 255);
            let g = Math.floor(Math.random() * 255);
            let b = Math.floor(Math.random() * 255);
            back_color = "rgba(" + r + "," + g + "," + b + ", 0.2)";          
            border_color = "rgba(" + r + "," + g + "," + b + ", 1)";          
        } else {
            back_color = colors_vector_back[j]
            border_color = colors_vector_border[j]
        }
     
		datasets_all.push({
			data: data_obj,
			showLine: true,
			label: dataMatrix.device,
			fill: false,
			backgroundColor: back_color,
			borderColor: border_color,
		});
        
        return null;
    });


    var data2 = {datasets: datasets_all}
    return (
   <> 
        {variable === 'Battery' ? (
            <Graph>
                <Scatter data={data2} options={options_battery} />
            </Graph>
        ) : variable === 'Acceleration' ? (
            <Graph>
                <Scatter data={data2} options={options_acceleration} />
            </Graph>
		) : variable === 'Distance' ? (
            <Graph>
                <Scatter data={data2} options={options_distance} />
            </Graph>
        ) :(
            <Graph>
                <Scatter data={data2} options={options_temperature} />
            </Graph>
        )}
    </>    
    
    )
}
 
export default Chart;