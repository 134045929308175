import React from 'react';
import styled from '@emotion/styled'
import XLSX from 'xlsx';
import moment from 'moment';

// import PerformFilter from './../../../utils/filter_and_offset_data.js'

function unixTime(unixtime) {

    var u = new Date(unixtime*1000);

      return u.getFullYear() +
        '-' + ('0' + (u.getMonth()+1)).slice(-2) +
        '-' + ('0' + u.getDate()).slice(-2) + 
        ' ' + ('0' + u.getHours()).slice(-2) +
        ':' + ('0' + u.getMinutes()).slice(-2) +
        ':' + ('0' + u.getSeconds()).slice(-2)
};

const ButtonDownloadExcel = (props) => {

    const variable = props.variable;

    function Workbook() {
        if (!(this instanceof Workbook))
            return new Workbook()
    
        this.SheetNames = []
        this.Sheets = {}
    }
    
    const download = (url, name) => {
        let a = document.createElement('a')
        a.href = url
        a.download = name
        a.click()
    
        window.URL.revokeObjectURL(url)
    }
    
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)

        for (let i=0; i !== s.length; ++i)
            view[i] = s.charCodeAt(i) & 0xFF
    
        return buf
    }
    

    const DownloadExcel = () => {
        var unix_time = (new Date().getTime()); 
        var readeable_time = (moment(unix_time).format("DD-MM-YYYY HH:mm:ss"));

        var sheets_data = {};
		var data_needed = [];

        if (props.dataFiltered) {
            var dataFiltered = props.dataFiltered;
			var device_list = []; // contains a list of non-repeated devices
			
			for (let device in dataFiltered){
				for (let i in dataFiltered[device]){
					if (dataFiltered[device][i] != undefined){
						if (variable == 'All'){
							delete dataFiltered[device][i].Pack;
							dataFiltered[device][i].Time = unixTime(dataFiltered[device][i].Timestamp);
							data_needed.push(dataFiltered[device][i]);
						}
						else{
							var value = parseFloat(dataFiltered[device][i][variable]);
							if (!isNaN(value)){
								delete dataFiltered[device][i].Pack;
								dataFiltered[device][i].Time = unixTime(dataFiltered[device][i].Timestamp);
								data_needed.push(dataFiltered[device][i]);
							}
						}
					}
				}
				sheets_data[device] = data_needed;
				data_needed = [];
			}

            const wb2 = new Workbook();
            for (var device in dataFiltered) {
                const ws2 = XLSX.utils.json_to_sheet(sheets_data[device]);
                wb2.SheetNames.push(device);
                wb2.Sheets[device] = ws2;
            }

            const wbout2 = XLSX.write(wb2, {bookType:'xlsx', bookSST:true, type: 'binary'});
            let url2 = window.URL.createObjectURL(new Blob([s2ab(wbout2)], {type:'application/octet-stream'}));
            download(url2, props.variable.concat(" - ").concat(readeable_time).concat('.xlsx'));

        }
    }


    const Button = styled.div ` 
        background-color:#ffffff;
    `

    const FileIcon = styled.div`
    text-anchor: middle;
    line-height: 0px;       
    margin-top: 2pt;
    margin-bottom: 1pt
    `

    return ( 
        <Button onClick={DownloadExcel} className="generalbuttonicon">
            {/* Download {props.variable} Excel */}
            <FileIcon>
                    <img src={"/imgs/excel-icon.svg"} width="25px" alt="pdf"></img>
            </FileIcon>
        </Button>
     );
}
 
export default ButtonDownloadExcel;