import React, { useState, Fragment } from "react";
import styled from '@emotion/styled'

// We will use these things from the lib https://react-google-maps-api-docs.netlify.com/
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow
} from "@react-google-maps/api";



function MapAux(props) {

  const IdStyle = styled.div ` 
      margin-top: 0pt;
      margin-bottom: 5pt;
  `

  // The things we need to track in state
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [infoOpen, setInfoOpen] = useState(false);


  // Load the Google maps scripts
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDtOy8KPFujuB4vPUXHFvwLhPtZqhjiDy4" // Enter your own Google Maps API key, AIzaSyDtOy8KPFujuB4vPUXHFvwLhPtZqhjiDy4
  });


  // Get locations object
  const locations = JSON.parse(props.locations);

  // Get device name replacers. Each replacer is associated with an ID (e.g. WBD_02 5395 is associated with 3F4D12)
  const scanTable = async (tableName) => {
      const params = {TableName: tableName};
      return await (props.dC.scan(params).promise()
          .then((data)=>{
            //   console.log("we have data in: " + tableName);
              return (data);
          })
          .catch((err) => {
              return (false);
          }));
  }

  scanTable('DeviceIdReplacer').then(data => {
      for (var i in locations) { // Iterate through the devices
          for (var j in locations[i]) { // Iterate throught the locations of a device
              for (var k in data.Items) { // Iterate through data, which associates the device ID with a replacer
                  if (data.Items[k].id == locations[i][j].name) { // If there's a replacer
                      locations[i][j]['replacer'] = data.Items[k].name; // Append the replacer name in locations, in the replacer field
                  } 
              }
          }
      }
  })



   // Get a list of all devices, avoid repetitions 
  const renderMap = (locations) => {

    var myLocations = locations;
    
      // Iterate myDevices to size, center, and zoom map to contain all markers
      const fitBounds = map => {
        const bounds = new window.google.maps.LatLngBounds();
        locations.map(device => {
          device.map(position => {
              bounds.extend({"lat": position.GPS_latitude, "lng": position.GPS_longitude});
              return position.id;
              // return device.id;
          })
        });
        map.fitBounds(bounds);
      };


      // Fit map bounds to contain all markers
      const loadHandler = map => {
        fitBounds(map);
      };

      // We have to create a mapping of our devices to actual Marker objects
      const markerLoadHandler = (marker, id) => {
        return setMarkerMap(prevState => {
          return { ...prevState, [id]: marker };
        });
      };

      // Remember which device was clicked
      const markerClickHandler = (event, device) => {
        setSelectedDevice(device);
        if (infoOpen) { // Required so clicking a 2nd marker works as expected
          setInfoOpen(false);
        }
        setInfoOpen(true);
      };



      return (
          <Fragment>
            <GoogleMap
              onLoad={loadHandler}
              mapContainerStyle={{
                height: "45vh",
                width: "95%",
                margin: "0 auto"
              }}
            >


              {myLocations.map(device => (
                device.map (location => (
                  <Marker
                      position={{"lat": location.GPS_latitude, "lng": location.GPS_longitude}}
                      onLoad={marker => markerLoadHandler(marker, String(location.name) + '_' + String(location.id))}
                      key={String(location.name) + '_' + String(location.id)} // in order to avoid a warning
                      onClick={event => markerClickHandler(event, location)}
                      icon={{url: 'http://www.googlemapsmarkers.com/v1/' + location.color + '/'}}
                  />
                ))
              ))}


              {infoOpen && selectedDevice && (
                <InfoWindow
                  // anchor={markerMap[selectedDevice.id]}
                  anchor={markerMap[String(selectedDevice.name) + '_' + String(selectedDevice.id)]}
                  onCloseClick={() => setInfoOpen(false)}
                >
                  <div>
                    <h3>{selectedDevice.replacer}</h3>
                    {/* <h5>{selectedDevice.name}</h5> */}
                    <IdStyle>ID: {selectedDevice.name}</IdStyle>
                    <p>Time: {selectedDevice.time}</p>
                    <IdStyle>Epoch: {selectedDevice.id}</IdStyle>
                    <p>Lat: {selectedDevice.GPS_latitude}</p>
                    <p>Long: {selectedDevice.GPS_longitude}</p>
                  </div>
                </InfoWindow>
              )}

            </GoogleMap>
          </Fragment>

      );
  };

  return isLoaded && locations ? renderMap(locations) : null;
}

export default MapAux;
