import React, { useState, useEffect } from 'react';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";// Asegúrate de importar tu instancia de CognitoUserPool
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie'
const Settings = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleChangePassword = async () => {
    try {
      const currentUser = UserPool.getCurrentUser();

      if (currentUser) {
        const authenticationDetails = new AuthenticationDetails({
          Username: currentUser.username,
          Password: oldPassword,
        });

        await new Promise((resolve, reject) => {
          currentUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => {
              resolve(session);
            },
            onFailure: (err) => {
              reject(err);
            },
          });
        });

        await new Promise((resolve, reject) => {
          currentUser.changePassword(oldPassword, newPassword, (err, result) => {
            if (err) {
              reject(err);
            } else {
              resolve(result);
            }
          });
        });

        console.log('Contraseña cambiada exitosamente');
      } else {
        console.error('No se encontró el usuario actual');
      }
    } catch (error) {
      console.error('Error al cambiar la contraseña', error.message);
    }
  };
	
  const history = useHistory();

  useEffect(() => {
    const idToken = Cookies.get('idToken');

    // If there is no valid token, redirect to login
    if (!idToken) {
      history.replace('/'); // Replace '/login' with the actual path to your login page
    }
  }, [history]);
  return (
    <div>
      <h2>Change Password</h2>
      <form>
        <label htmlFor="oldPassword">Old Password:</label>
        <input
          type="password"
          id="oldPassword"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />

        <label htmlFor="newPassword">New Password:</label>
        <input
          type="password"
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />

        <label htmlFor="confirmNewPassword">Confirm New Password:</label>
        <input
          type="password"
          id="confirmNewPassword"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />

        <button type="button" onClick={handleChangePassword}>
          Change Password
        </button>
      </form>
    </div>
  );
};

export default Settings;
