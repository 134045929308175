import React, { useState, useEffect } from 'react';
import { get_docClient } from '../../AWS-SDK/AWS';
import { Line } from 'react-chartjs-2';
import "./graphs.css";


const UsersDevicesTable = () => {

  const [inhalers, setInhalers] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedData, setSelectedData] = useState([]); // Store data for selected devices

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const timeFilterOptions = [
    { value: 7, label: 'Last Week' },
    { value: 30, label: 'Last Month' },
    { value: 60, label: 'Last 2 Months' },
    { value: 180, label: 'Last 6 Months' },
    { value: 365, label: 'Last Year' },
   // { value: null, label: 'All'}
  ];
  useEffect(() => {
    fetchInhalers(); // Fetch inhaler names if needed
  }, []);
  
 // Function to calculate min and max battery values
  const calculateBatteryMinMax = () => {
    const allBatteryValues = sortedData.map(item => item.battery);
    const minBatteryValue = Math.min(...allBatteryValues);
    const maxBatteryValue = Math.max(...allBatteryValues);

    // Return the min and max values
    return { minBatteryValue, maxBatteryValue };
  };
  const fetchInhalers = async () => {

    try {

      const docClient = get_docClient();
      const params = {
        TableName: 'WiFi_Inhaler_Devices',
      };

      const data = await docClient.scan(params).promise();
      const inhalerNames = data.Items.map(item => item.Device_Name);
      setInhalers(inhalerNames);
      console.log('inhalers', inhalerNames);
    } catch (error) {
      console.error('Error fetching inhaler names', error);
    }

  };

  const handleDateFilter = async () => {
    console.log('Start Date Filter:', startDateFilter);
    console.log('End Date Filter:', endDateFilter);

    const filteredData =[];
   
    const startUnixTime = new Date(startDateFilter).getTime() / 1000;
    const endUnixTime = (new Date(endDateFilter).getTime() / 1000) + 86400; // Adding 24 hours to include the end day
  
    console.log('startUnixTime', startUnixTime);
    console.log('endUnixTime', endUnixTime);
         
    for (const deviceName of selectedDevices) {
      const tableData = await fetchTableData(deviceName, startUnixTime, endUnixTime);
      const deviceData = tableData.map(item => ({
        ...item,
        device: deviceName,
      }));
      filteredData.push(...deviceData);
    }
    
    setSelectedData(filteredData);
    console.log('selectedData after filtering', selectedData);
  }; 
  
  const handleInhalerCheckboxChange = (event) => {

    const inhalerName = event.target.value;
    if (event.target.checked) {
      setSelectedDevices(prevSelected => [...prevSelected, inhalerName]);
    } else {
      setSelectedDevices(prevSelected => prevSelected.filter(name => name !== inhalerName));

    }

  };

  const processTimestamp = (timestamp) => {

    const [date, time] = timestamp.split('T');
    const [day, month, year] = date.split('-');
    const [hour, minute, second] = time.split(':');
    const utcTimestamp = Date.UTC(year, month - 1, day, hour, minute, second);

    return new Date(utcTimestamp).toLocaleString();

  };

  
  const handleDisplaySelected = async () => {
    const mergedData = [];
    console.log('device names:', selectedDevices);
    for (const deviceName of selectedDevices) {
      const tableData = await fetchTableData(deviceName, null, null);
      const deviceData = tableData.map(item => ({
        ...item,
        device: deviceName,
      }));
      mergedData.push(...deviceData);
    }
    // Update the selectedData state with the merged data
    setSelectedData(mergedData); // Use await here
    //console.log('selectedData:', selectedData);

    const { minBatteryValue, maxBatteryValue } = calculateBatteryMinMax();

    // Update batteryGraphData.plugins with new min and max values
    batteryGraphData.plugins = [
      {
        id: 'batteryMinMaxLabels',
        afterDraw: (chart, args, options) => {
          const { ctx, scales } = chart;
          const { left, top } = chart.chartArea;

          console.log('left:', left);
          console.log('top:', top);

          ctx.save();
          ctx.textAlign = 'left';
          ctx.textBaseline = 'top';
          ctx.fillStyle = '#000'; // Set the color of the labels

          // Display minimum value
          ctx.fillText(`Min: ${minBatteryValue}`, left, top);

          // Display maximum value
          ctx.fillText(`Max: ${maxBatteryValue}`, left, top + 15);

          ctx.restore();
        },
      },
    ];


  };
  
  
 const handleTimeFilter = async (numDays) => {
    const currentDate = new Date();
    const endDate = currentDate.toISOString().split('T')[0];
    
    const subtractedDate = new Date();
    subtractedDate.setDate(currentDate.getDate() - numDays);
    const startDate = subtractedDate.toISOString().split('T')[0];
  
    setStartDate(startDate);
    setEndDate(endDate);
  
    const startUnixTime = new Date(startDate).getTime() / 1000;
    const endUnixTime = (new Date(endDate).getTime() / 1000) + 86400; // Adding 24 hours to include the end day

    console.log('startUnixTime', startUnixTime);
    console.log('endUnixTime', endUnixTime);
    const filteredData = [];    
    for (const deviceName of selectedDevices) {
      const tableData = await fetchTableData(deviceName, startUnixTime, endUnixTime);
      const deviceData = tableData.map(item => ({
        ...item,
        device: deviceName,
      }));
      filteredData.push(...deviceData);
    }
    setSelectedData(filteredData);
    console.log('selectedData after filtering', selectedData);
  }; 
  
  const fetchTableData = async (deviceName, startUnixTime, endUnixTime) => {
    console.log('deviceName: ', deviceName);
    try {
      const docClient = get_docClient();
      const params = {
        TableName: deviceName,
      };
      const data = await docClient.scan(params).promise();
      console.log('data: ', data.Items);
      // Sort the filtered data by Unix_Time in ascending order
     
      if (startUnixTime !== null && endUnixTime !== null) {
          // Filter the combined data for all selected devices
        const filteredData = data.Items.filter(item => {
        const unixTime = item.Unix_Time; // Replace with the actual attribute name in your table
        return (!startUnixTime || unixTime >= startUnixTime) &&
                   (!endUnixTime || unixTime <= endUnixTime);
      });
  
        return filteredData;
      } else {
        // If startUnixTime and endUnixTime are not provided, return all data
        return data.Items;
      }
    } catch (error) {
      console.error('Error fetching table data', error);
      return [];
    }
  };
  
  

 // Update your dosesGraphData and batteryGraphData like this:
 function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
// console.log('selectedData before sorting:', selectedData);
// ordenar la selectedData, conté la info de les taules ordenades
const sortedData = selectedData.slice().sort((a, b) => a.Unix_Time - b.Unix_Time);

const dosesGraphData = {
  labels: sortedData.map(item => processTimestamp(item.timestamp)),
  datasets: selectedDevices.map(deviceName => ({
    label: `Doses (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item.timestamp),
        y: item.doses,
      })), // Map doses data for the device with x and y values
    fill: true,
    borderColor: getRandomColor(), // Generate a color for the line
    tension: 0.1,
    steppedLine: 'before',
  })),
};

const batteryGraphData = {
  labels: sortedData.map(item => processTimestamp(item.timestamp)),
  datasets: selectedDevices.map(deviceName => ({
    label: `Battery (${deviceName})`,
    data: sortedData
      .filter(item => item.device === deviceName) // Filter data for the specific device
      .map(item => ({
        x: processTimestamp(item.timestamp),
        y: item.battery,
      })),
    fill: false,
    borderColor: getRandomColor(), // Generate a color for the line
    tension: 0.1,
  })),
};
console.log('sortedData:', sortedData);

return (
  <div>
  
      <div className="header-container">
        <header className="auth-app-header">
            <div className="auth-logo">
                <img src={"/imgs/logo-sterna-st.png"} alt="Logo" />
                <h1 className="teva">
                    <span>WiFi PoC Dashboard</span>
                </h1>
            </div>
        </header>
      </div>
  
      <div className='container-opt'>
        <div className='checkbox-container'>
          <h2>Select inhalers to display</h2>
          {inhalers.map(inhalerName => (
            <div key={inhalerName} className="inhaler-label">
              <input
                type="checkbox"
                value={inhalerName}
                checked={selectedDevices.includes(inhalerName)}
                onChange={handleInhalerCheckboxChange}
              />
              {inhalerName}
            </div>
          ))}
          <div className='button-wrapper'>
            <button className="apply-button" onClick={handleDisplaySelected}>Display</button>
          </div>
        </div>

        <div className="select-container">
            <div className='header-select'>
              <h2>Filter data by time</h2>
            </div>
            <div className="select-wrapper">
            <select onChange={(e) => handleTimeFilter(Number(e.target.value))}>
              <option value="" disabled selected>Select Time Filter</option>
              {timeFilterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='dates-button-container'>
          <div className="date-filter-container">
            <div className="date-filter">
              <h2 className="date">Start Date:</h2>
              <input
                type="date"
                id="startDate"
                value={startDateFilter}
                onChange={(e) => setStartDateFilter(e.target.value)}
              />
            </div>
            <div className="date-filter">
            <h2 className="date">End Date:</h2>
              <input
                type="date"
                id="endDate"
                value={endDateFilter}
                onChange={(e) => setEndDateFilter(e.target.value)}
              />
            </div>
          </div>
          
          <div className='apply-button-wrapper'>
            <button className="apply-dates-button" onClick={handleDateFilter}>Apply Date Filter</button>
          </div>
        </div>
      </div>


      <div className='container'>
        <div className='centered-content'>
          <div style={{ display: 'flex', gap: '100px' }}>
            {selectedData.length > 0 && (
              <>
              <div className="graph-section">
                <h2>Doses-TimeStamp graph</h2>
                  <div className='graph'>
                    <Line data={dosesGraphData} />
                  </div>
              </div>
              
              <div className="graph-section">
             
                <h2>Battery-TimeStamp graph</h2>
                  <div className='graph'>
                  <Line data={batteryGraphData} plugins={batteryGraphData.plugins} />
                  </div>
              </div>
            </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersDevicesTable;