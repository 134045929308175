// AWS credentials
var AWS = require("aws-sdk");
var credentials = require('./credentials.json'); //(with path)

AWS.config.update({
    accessKeyId: credentials.aws_access_key_id,
    secretAccessKey: credentials.aws_secret_access_key,
    region:'eu-west-1',
    version: 'latest'
});

AWS.config.getCredentials(function(err) {
    if (err) { console.log("credentials not loaded: " + err.stack);}
    // else { console.log("AWS Access key:", AWS.config.credentials.accessKeyId); }
});

let docClient = new AWS.DynamoDB.DocumentClient({correctClockSkew: true});

// table scan Function returns table existence and items
export const scanTable = async (tableName) => {
    const params = {
        TableName: tableName,
		};
    let scanResults = [];
	let lastkey;
	do{
		await (docClient.scan(params).promise()
			.then((data)=>{
				data.Items.forEach((item) => {scanResults.push(item)});
				params.ExclusiveStartKey = data.LastEvaluatedKey;
				lastkey = data.LastEvaluatedKey;
			})
			.catch((err)=>{
				return {'exist':false, 'items':[]};
			}));
	}while (typeof lastkey != "undefined");
    return {'exist':true, 'items':scanResults};
};

// return docClient

export function get_docClient(){
	return docClient;
}
 