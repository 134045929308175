import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import './App.css';
import './../navigation.css';
// Cognito
import { useHistory } from 'react-router-dom';

// LayouAt
import Content from './components/Layout/content';
import Footer from './components/Layout/footer';

// Context
import { SelectContext } from './components/Selection/context';

// Sort function
import Sort from './utils/sort';

// AWS functions
import UsersDevicesTable from './components/Selection/graphs';
// Third-Party Libraries
import Cookies from 'js-cookie';

// Error message
var err_msg = "Default error message!";


function App () {
	
	const App = styled.div`
        position: absolute;
        min-height: 100%;
        min-width: 100%;
    `

    const Container = styled.div`
        margin: 0 auto;
        margin-bottom: 40px;
        font-size: 40px;
    `
	
	// Screen state
  const history = useHistory();

  useEffect(() => {
    const idToken = Cookies.get('idToken');

    // If there is no valid token, redirect to login
    if (!idToken) {
      history.replace('/loginCl'); // Replace '/login' with the actual path to your login page
    }
  }, [history]);

    return (  
        <div className="App">
			<Helmet>
				<title>Clear Solar Devices</title>
			</Helmet>
				<App>
					<UsersDevicesTable />
                    <Footer />
				</App>
        </div>
    );  
}  



export default App;

// https://www.quora.com/What-are-some-good-ways-to-extract-one-single-column-from-a-DynamoDB-table
