import React from 'react';
import styled from '@emotion/styled'
import {Polar, Radar} from 'react-chartjs-2';

const DistGraph = (props) => {
	
	const SectionBar = styled.div ` 
        background-color: white;
		padding: 10px 0px 0px 0px;
		border-color: #000000;
		border-width: 1pt;
		border-style: solid;
		margin: 0 auto;
		vertical-align: middle;
        color: black;
        border-radius: 0pt;
		align-items: center;
    `
	
	const SectionHeader = styled.div ` 
		background-color: #394475;

		display: flex;
		justify-content: space-between;
		color: black;
		font-size: 20px;
		list-style: none;
		line-height: 20px;

		padding: 3px 10px 3px 10px;
		border-radius: 0pt;
		border-color: #000000;
		border-width: 0pt;
		border-style: solid;
		top: 50%;

		margin: 0 auto;
		vertical-align: middle;
		border-color: #000000;
		border-width: 1pt;
    `
	
	const MarginHeader = styled.div ` 
        margin-top: 4pt;
		margin-bottom: 4pt;
        line-height: 20px;
        color: white;
    `
	
	const Section = styled.div ` 
        /* background-color: #e8e8e8, */
        margin-bottom: 20pt;
		width: 100%;
    `
	
	const options = {
		scale: {
			ticks: {
				min: 0,
			},
        },
        legend: {
            display: true,
			position: 'bottom',
			labels:{
				fontColor: 'black',
				fontStyle: 'bold'
			}
        }
    };
	
	var devices = props.devices;
	var data = props.data;
	let chart_ready = false;
	var graph_data = {};
	
	if (devices.length > 0 && Object.keys(data).length > 0){
		var values = [];
		for (var i in devices){
			values.push(data[devices[i]]['Distance']);
		}
		graph_data = {
			labels: devices,
			datasets: [
				{
					//label: 'Distances',
					data: values,
					borderWidth: 1,
					backgroundColor: [
						'rgba(255, 0, 0, 0.2)',
						'rgba(0, 255, 0, 0.2)',
						'rgba(0, 0, 255, 0.2)',
					],
					borderColor: [
						'rgba(255, 0, 0, 1)',
						'rgba(0, 255, 0, 1)',
						'rgba(0, 0, 255, 1)',
					]
				}
			]
		}
		chart_ready = true;
	}

    return ( 
		<Section>
			<SectionHeader>
				<MarginHeader>
					{"Distance Graph"}
				</MarginHeader>
			</SectionHeader>
			<SectionBar>
				{chart_ready ? (
					<Polar data={graph_data} options={options} />
				):(
					<div>Charging...</div>
				)}
			</SectionBar>
		</Section>
     );
}
 
export default DistGraph;