import React from 'react';
import styled from '@emotion/styled'


const Footer = () => {

    const Footer = styled.div ` 
        font-weight: 50;
        font-size: 0.60 em;

        text-align: center;
        padding: 0em 0.4em 0em 0.4em;
        background-color: #222222;
        color: white; 

        height: 110px;
        border-radius:0px;

        list-style: none;
        line-height: 40px;
        text-justify: inter-word;

        position: absolute;
        bottom: 0;
        right: 0;
        width: 100% ;
     `


    return ( 
        <Footer>
            <li>
                Copyright &copy;{new Date().getFullYear()} Sterna Innovation Projects - All Rights Reserved
            </li>
            <li>
                <a href="https://sternainnovation.com/">
                    <img src="/imgs/sterna-logo-w.png" alt="https://sternainnovation.com/" padding="0" width="150em" ></img>
                </a>
            </li>
        </Footer>
     );
}
 
export default Footer;