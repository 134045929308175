import React, { createContext, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./UserPool";
import { useState } from "react";
import Cookies from "js-cookie";

// El context de l'autenticació de l'usuari s'exporta com 'AccountContext'   
const AccountContext = createContext();
// getSession és una funció asíncrona que espera la resolució o el rebuig d'una promise
const Account = props => {
  // idtoken state
  const [idToken, setIdToken] = useState(() => {
    // Retrieve the idToken from a cookie on component initialization
    return Cookies.get("idToken") || null;
  });
  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser(); //obtenim l'usuari actual
      if (user) { // si tenim usuari actual autenticat, cridem getsession per obtenir la sessió actual 
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else { // si no hi ha cap error
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => { // la funció retorna atributs de la forma {nom, valor}
                if (err) {
                  reject(err);
                } else {
                  const results = {};
                  
                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                  // resol la promesa interna amb results (atributs de l'usuari)
                  resolve(results);
                }
              });
            });
            
            resolve({
              user,
              ...session,
              ...attributes
            });
          }
        });
      } else {
        reject();
      }
    });
// a partir de l'username i el password s'autentica l'usuari
// es mira que tingui una instància a CognitoUserPool
  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
// s'autentica amb les credencials proporcionades
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          const idToken = data.getIdToken().getJwtToken();
          setIdToken(idToken); // Use setIdToken (with capital T) to set the idToken state
          resolve(data);
          Cookies.set("idToken", idToken, { expires: 7 }); // Store the idToken in a cookie that expires in 7 days
          console.log("token cookie:", Cookies.get("idToken"));
          console.log("token id:", idToken);
          console.log("onSuccess:", data);
        },

        onFailure: err => {
          // console.error("onFailure:", err);
          reject(err);
        },

        newPasswordRequired: data => {
          // console.log("newPasswordRequired:", data);
          resolve(data);
        }
      });
    });

   /* const logout = () => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.signOut();
      }
      setIdToken(null);
      Cookies.remove("idToken"); // Remove the idToken cookie on logout
    };*/
   useEffect(() => {
      if (idToken) {
        Cookies.set("idToken", idToken, { expires: 7 }); // Store the idToken in a cookie that expires in 7 days
        console.log("token cookie:", Cookies.get("idToken"));
        console.log("token id:", idToken);

      }
    }, [idToken]);
    // Function to check token expiration
  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession
      //  logout // passem el token al context
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
// retorna account que encapsula i proporciona l'estat i les funcions relacionades amb l'autenticació i la sessió
export { Account, AccountContext };

