import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import './App.css';
import './../navigation.css';
import './components/Selection/gesab.css'
import { Link } from 'react-router-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App_Log from './../loginSignup/App';
// Cognito


// LayouAt
import Footer from './components/Layout/footer';

// Context
import { SelectContext } from './components/Selection/context';

// Sort function
import Sort from './utils/sort';

// AWS functions
import UsersDevicesTable from './components/Selection/historicGesab';
// Third-Party Libraries
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import UsersDevicesTableDashboard from './components/Selection/dashboard';
import UserRegister from './loginSignup/UserAuthentication/userRegister';
import UserModify from './loginSignup/UserAuthentication/userModify';
import UserPool from './loginSignup/UserAuthentication/UserPool';

// Error message
var err_msg = "Default error message!";


function App () {
	
	const App = styled.div`
        position: absolute;
        min-height: 100%;
        min-width: 100%;
    `
	
	// Screen state
	
    const history = useHistory();

    useEffect(() => {
      const idToken = Cookies.get('idToken');
      // If there is no valid token, redirect to login
      if (!idToken) {
        history.replace('/loginGe'); // Replace '/login' with the actual path to your login page
      }
    }, [history]);
    var isAdmin
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else if (!session.isValid()) {
        console.log("Invalid session.");
      } else {
        // console.log("IdToken: ", session.getIdToken().getJwtToken());
        isAdmin = session.idToken.payload['custom:Admin'];
        // console.log("Session info: ", session);
        // console.log("is Admin? ", isAdmin);	// Accedir només a la propietat desitjada ¿En algun moment es dirà custom:projectes?
      }
      });
    } else {
      console.log("User not found.");
    }

    return (  
        <div className="App">
			<Helmet>
				<title>Gesab</title>
        <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'></link>
			</Helmet>
				<App>
          <div className="header-container">
              <header className="auth-app-header">
                  <div className="auth-logo">
                      {/* <img src={"/imgs/logo-sterna-st.png"} alt="Logo" /> */}
                      <h1 className="gesab">
                          <span>Gesab Consoles Dashboard</span>
                      </h1>
                  </div>
              </header>
          </div>
          {/* <main> */}
            <div class="lateral">
              <ul class="menuVert">
                

              <li><Link to="/gesab/home" className="dashboard-link">
                    <img src="..\..\imgs\gesab_images\home.png"
                                  alt='Link to user dashboard'
                                  width={50}
                                  height={50}/>

                </Link></li>

                <li><Link to="/gesab/console" className="dashboard-link">
                    <img src="..\..\imgs\gesab_images\console.png"
                                  alt='Link to user dashboard'
                                  width={50}
                                  height={50}/>

                </Link></li>

                <li><Link to="/gesab/room" className="dashboard-link">
                    <img src="..\..\imgs\gesab_images\room.png"
                                  alt='Link to user dashboard'
                                  width={50}
                                  height={50}/>

                </Link></li>              

                <li><Link to="/gesab/dashboard" className="dashboard-link">
                    <img src="..\..\imgs\gesab_images\home.png"
                                  alt='Link to user dashboard'
                                  width={50}
                                  height={50}/>

                </Link></li>
                
                <li><Link to="/gesab/historic" className="dashboard-link">
                    <img src="..\..\imgs\gesab_images\console.png"
                                  alt='Link to user historic'
                                  width={50}
                                  height={50}/>
                </Link></li>

                {isAdmin == 1 ?(
                <li><Link to="/gesab/admin" className="dashboard-link">
                    <img src="..\..\imgs\gesab_images\user.png"
                                  alt='Link to user dashboard'
                                  width={50}
                                  height={50}/>

                </Link></li>) : (<li><Link to="/gesab/user" className="dashboard-link">
                    <img src="..\..\imgs\gesab_images\settings.png"
                                  alt='Link to user dashboard'
                                  width={50}
                                  height={50}/>

                </Link></li>
                )}

              </ul>
            </div>
          {/* </main> */}
          <Switch>
                <Route path='/gesab/historic' component={UsersDevicesTable} title="gesab graphs"/>
                <Route path='/gesab/dashboard' component={UsersDevicesTableDashboard} title="gesab dashboard"/>
                <Route path='/gesab/admin' component={UserRegister} title="User Register"/>
                <Route path='/gesab/user' component={UserModify} title="User Register"/>
                <Route path='/gesab/home'>
                    <div className='principal'>
                      <img src="..\..\imgs\gesab_images\homeDash.png"
                                  alt='Link to user historic'
                                  width={1200}
                                  height={750}/>
                    </div>
                </Route>
                <Route path='/gesab/console'>
                    <div className='principal'>
                      <img src="..\..\imgs\gesab_images\consoleDash.png"
                                  alt='Link to user historic'
                                  width={1200}
                                  height={750}/>
                    </div>
                </Route>
                <Route path='/gesab/room'>
                    <div className='principal'>
                      <img src="..\..\imgs\gesab_images\roomDash.png"
                                  alt='Link to user historic'
                                  width={1200}
                                  height={570}/>
                    </div>
                </Route>
          </Switch>
					{/* <UsersDevicesTableDashboard /> */}
					<Footer />
				</App>
        </div>
    );  
}  



export default App;