import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import styled from '@emotion/styled'
import { Link } from 'react-router-dom';
import ArrowIcon from './ArrowIcon';
import Header from './header';
import './menu.css';
import Cookies from "js-cookie";
import UserPool from './loginSignup/UserAuthentication/UserPool';

const Main = styled.div`
        padding: 10pt 10pt 20pt 10pt;
        text-align: center;
        background-color: #a3a3a3;
        width: 400px;
        margin: 0 auto;
        margin-top: 50px;
        font-size: 16pt;

        border: 0.08em solid #000000;
        border-radius: 5pt;
    `;
	

const TileContent = styled.div`
		display: flex;
		align-items: center;
`;



const Menu = () => {

	const [userEmail, setUserEmail] = useState(''); // user Email
	const [isUserLogged, setIsUserLogged] = useState(false); // to check if the whole webpage should be shown or not
	const history = useHistory();

	useEffect(() => {
	  const idToken = Cookies.get('idToken');
  
	  // If there is no valid token, redirect to login
	//   if (!idToken) {
	// 	history.replace('/'); // Replace '/login' with the actual path to your login page
	//   }
	}, [history]);

	const handleLogout = () => {
		// Call the logout function from Accounts.js to handle the logout
		// (you may need to import the logout function from Accounts.js)
		// For example, if the logout function is exported as 'logout':
		// logout();
		setIsUserLogged(false);
		// console.log("id token before removing cookie:", Cookies.get("idToken"));
		Cookies.remove("idToken");
		// console.log("token cookie:", Cookies.get("idToken"));
		window.location.href = '/';
	};


	// Accedir a les dades de l'usuari loggejat
	const cognitoUser = UserPool.getCurrentUser();
	if (cognitoUser != null) {
		cognitoUser.getSession((err, session) => {
		if (err) {
			console.log(err);
		} else if (!session.isValid()) {
			console.log("Invalid session.");
		} else {
			// console.log("IdToken: ", session.getIdToken().getJwtToken());
			console.log("Session info: ", session);
			console.log("Session user info: ", session.idToken.payload['custom:nom']);	// Accedir només a la propietat desitjada ¿En algun moment es dirà custom:projectes?
		}
		});
	} else {
		console.log("User not found.");
	}

    return (
		<div>
			<Header user={userEmail} handleLogout={handleLogout}/>
			<div className="app-body-main-content">
				<section className="service-section">
				<div className="tiles">
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>Weather Station</span>
						</h3>
					</div>
						<Link to="/iot" className="dashboard-link"> 
							<TileContent>
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
						
					</article>
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>eDelivery</span>
						</h3>
					</div>
						<Link to="/econtainer" className="dashboard-link">
							<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
					</article>
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>eEfficiency</span>
						</h3>
					</div>
						<Link to="/eEfficiency" className="dashboard-link" >
							<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
					</article>
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>eSpot</span>
						</h3>
					</div>
						<Link to="/eSpot" className="dashboard-link" > 
							<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
					</article>
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>WiFi PoC</span>
						</h3>
					</div>
						<Link to="/wifipoc" className="dashboard-link" >
						<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
					</article>					
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>Clear Solar</span>
						</h3>
					</div>
						<Link to="/clearsolar" className="dashboard-link" >
						<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
					</article>
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>GESAB</span>
						</h3>
					</div>
						<Link to="/gesab" className="dashboard-link" >
						<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
					</article>
				</div>
				</section>
			</div>

		</div>
    );
}
 
export default Menu;


/*import React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled'
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import ArrowIcon from './ArrowIcon';
import Header from './header';
import './menu.css';
import Cookies from "js-cookie";
const Main = styled.div`
        padding: 10pt 10pt 20pt 10pt;
        text-align: center;
        background-color: #a3a3a3;
        width: 400px;
        margin: 0 auto;
        margin-top: 50px;
        font-size: 16pt;

        border: 0.08em solid #000000;
        border-radius: 5pt;
    `;
	

const TileContent = styled.div`
		display: flex;
		align-items: center;
`;



const Menu = () => {

	const [userEmail, setUserEmail] = useState(''); // user Email
	const [isUserLogged, setIsUserLogged] = useState(false); // to check if the whole webpage should be shown or not

	const handleLogout = () => {
		// Call the logout function from Accounts.js to handle the logout
		// (you may need to import the logout function from Accounts.js)
		// For example, if the logout function is exported as 'logout':
		// logout();
		setIsUserLogged(false);
		console.log("id token before removing cookie:", Cookies.get("idToken"));
		Cookies.remove("idToken");
		console.log("token cookie:", Cookies.get("idToken"));
	};


    return (
		<div>
			<Header user={userEmail} handleLogout={handleLogout}/>
			<div className="app-body-main-content">
				<section className="service-section">
				<div className="tiles">
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>Weather Station</span>
						</h3>
					</div>
						<a href="#">
						<Link to="/iot" className="dashboard-link"> 
							<TileContent>
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
						</a>
					</article>
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>eDelivery</span>
						</h3>
					</div>
						<a href="#">
						<Link to="/econtainer" className="dashboard-link">
							<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
						</a>n
					</article>
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>eEfficiency</span>
						</h3>
					</div>
						<a href="#">
						<Link to="/eEfficiency" className="dashboard-link" >
							<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
						</a>
					</article>
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>eSpot</span>
						</h3>
					</div>
						<a href="#">
						<Link to="/eSpot" className="dashboard-link" > 
							<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
						</a>
					</article>
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>WiFi PoC</span>
						</h3>
					</div>
						<a href="#">
						<Link to="/teva" className="dashboard-link" >
						<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
						</a>
					</article>					
					<article className="tile">
					<div className="tile-header">
						<h3>
							<span>Clear Solar</span>
						</h3>
					</div>
						<a href="#">
						<Link to="/clearsolar" className="dashboard-link" >
						<TileContent> 
								<span>Go to DashBoard</span>
								<span className="icon-button">
								 <ArrowIcon />
								</span>
							</TileContent>
						</Link>
						</a>
					</article>

				</div>
				</section>
			</div>

		</div>
    );
}
 
export default Menu;
 */