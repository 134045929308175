import React from 'react'
import styled from '@emotion/styled'

const MaxContainer = (props) => {
    var variable = props.variable;
    const cnf = props.configuration;
    var value = null;

    if (typeof(props.value) != 'string'){
        // Choose background color
        value = props.value.toFixed(2); // we limit the number to only 2 decimal places
        value = parseFloat(value);

        var Color = `rgb(0, 0, 0)`;
        switch (variable) {
            case 'temperature' :
            case 'radiation':
                if (value > cnf.threshold) {
                    Color = 255 * (1 - (value - cnf.threshold) / (cnf.max - cnf.threshold));
                    Color =  `rgb(255, ${Color}, 0)`
                }
                else if (value <= cnf.threshold) {
                    Color = 190 * (1 - (cnf.threshold - value) / (cnf.threshold - cnf.min));
                    Color =  `rgb(${Color}, 0, 255)`
                }
                break;
            case 'wind' : 
                Color = 255 * (1 - ((value) / (cnf.max - cnf.min)));
                Color =  `rgb(${Color}, ${Color}, 255)`;
                break;
            case 'humidity' :
            case 'moisture' : 
                if (value > cnf.threshold) {
                    Color = 255 * (1 - (value - cnf.threshold) / (cnf.max - cnf.threshold));
                    Color =  `rgb(0, ${Color}, 255)`
                }
                else if (value <= cnf.threshold) {
                    Color = 255 * (1 - (cnf.threshold - value) / (cnf.threshold - cnf.min));
                    Color =  `rgb(${Color}, 255, 0)`
                }
                break;
            case 'rain' :
                Color = 255 * (1 - ((value) / (cnf.max - cnf.min)));
                Color =  `rgb(${Color}, ${Color}, 255)`;
                break;
            case 'battery' :
            case 'leafwetness' :
                if (value > cnf.threshold) {
                    Color = 255 * (1 - (value - cnf.threshold) / (cnf.max - cnf.threshold));
                    Color =  `rgb(${Color}, 255, 0)`
                }
                else if (value <= cnf.threshold) {
                    Color = 255 * ((cnf.threshold - value) / (cnf.threshold - cnf.min));
                    Color =  `rgb(255, ${Color}, 0)`
                }
                break;
            default: break;
        };
    } else {
        value = props.value;
    }    



    const DataContainer = styled.div`
        margin: 0 auto;
        margin-bottom: 5px;

        background-color: #bdbdbd;

        width: 250px;
        height: 240 px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center; 
        border-radius: 5px;
        line-height: 18pt; 
    `

    const Tittle = styled.div `
        border: 0 auto;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 
        border-radius: 0px; 

        font-weight: 500;
        font-size: 14pt;

        background-color: #a3a3a3; 
    `

    const Data = styled.div `
        border: 0 auto;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 
        border-radius: 0px; 

        font-weight: 500;
        font-size: 22pt;
        padding: 0.25em 0em 0.40em 0em;

        background: ${Color};
    `

    const Timestamp = styled.div `
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 

        font-weight: 400;
        font-size: 10pt;
        padding: 0.2em 0em 0.5em 0em;
        /* margin-top: 0pt; */
        line-height: 5pt;

        background: #a3a3a3;
    `
    
    var title = ("Maximum " + String(variable)).toUpperCase();

    return (
        <DataContainer>
           <Tittle> {title} </Tittle>
            {typeof(value) != 'string' ? (
                    <>
                        <Timestamp> {props.timestamp} </Timestamp>
                        <Data> {value} {cnf.units} </Data>
                    </>
                ) : (
                    <Data> {value}  </Data>
            )}
        </DataContainer>
    ); 
}
 
export default MaxContainer;
