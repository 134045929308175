import React, {useState, useContext, useEffect} from 'react';
import styled from '@emotion/styled'

import GraphSection from './graph_section'
import ButtonDownloadExcel from './buttons/ButtonDownloadExcel';
import ButtonDownloadPDF from './buttons/ButtonDownloadPDF';

import {SelectContext} from './../Selection/context';

// Sort function
import Sort from './../../utils/sort';

// AWS credentials
var AWS = require("aws-sdk");
var credentials = require('./../../AWS-SDK/credentials.json'); //(with path)

AWS.config.update({
    accessKeyId: credentials.aws_access_key_id,
    secretAccessKey: credentials.aws_secret_access_key,
    region:'eu-west-1',
    version: 'latest'
});

AWS.config.getCredentials(function(err) {
    if (err) { console.log("credentials not loaded: " + err.stack);}
    // else { console.log("AWS Access key:", AWS.config.credentials.accessKeyId); }
});

let docClient = new AWS.DynamoDB.DocumentClient({correctClockSkew: true});

// table scan Function returns table existence and items
const scanTable = async (tableName) => {
    const params = {
        TableName: tableName,
		};
    let scanResults = [];
	let lastkey;
	do{
		await (docClient.scan(params).promise()
			.then((data)=>{
				data.Items.forEach((item) => {scanResults.push(item)});
				params.ExclusiveStartKey = data.LastEvaluatedKey;
				lastkey = data.LastEvaluatedKey;
			})
			.catch((err)=>{
				return {'exist':false, 'items':[]};
			}));
	}while (typeof lastkey != "undefined");
    return {'exist':true, 'items':scanResults};
};

function unixDate(unixtime) {

    var u = new Date(unixtime*1000);

      return u.getFullYear() +
        '-' + ('0' + (u.getMonth()+1)).slice(-2) +
        '-' + ('0' + u.getDate()).slice(-2)
};

const Content = (props) => {

    const Button = styled.div ` 
        background-color:#ffffff;
        margin: 0 auto;
        margin-left: 5pt;
    `

    const SectionHeader = styled.div ` 
        background-color: #394475;

        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
        list-style: none;
        line-height: 20px;

        padding: 3px 10px 3px 10px;
        border-radius: 0pt;
        border-color: #000000;
        border-width: 0pt;
        border-style: solid;

        position: sticky;
        margin: 0 auto;
        vertical-align: middle;
        top: 55px;

        z-index: 102;
    `
	
	const BlankSpace = styled.div ` 
        background-color: #FFFFFF;
        display: flex;
        padding: 4px 0px 0px 0px;
        position: sticky;
        margin: 0 auto;
        top: 96px;
        z-index: 102;
    `

    const Section = styled.div`
        width: 70%; /* width of the content of the whole page */
        margin: 0 auto;
        margin-bottom: 150px;
    `

    const SectionD = styled.div`
        width: 100%; /* width of the content of the whole page */
        margin: 0 auto;
        margin-bottom: 20pt;
    `

    const MarginHeader = styled.div ` 
        margin-top: 4pt;
        line-height: 20px;
        color: white;
    `
	
	const FileIcon = styled.div`
		text-anchor: middle;
		line-height: 0px;       
		margin-top: 2pt;
		margin-bottom: 1pt
    `

    const {isChoiceSubmitted, setIsChoiceSubmitted, selectedDevices, setSelectedDevices} = useContext(SelectContext);
	const [devicesData, setDevicesData] = useState(null);
	const [auxState, setAuxState] = useState(null);
	
	var devNames = props.devNames;
	var paramNames = props.paramNames;
	
	var now = new Date();
	var current_time = now.getTime()/1000;
	
	const [startTime, setStartTime] = useState(current_time - 2592000); // 1 month by default
	const [endTime, setEndTime] = useState(current_time + 86400); // add 1 day to show current day data
	const startDate = React.useRef();
	const endDate = React.useRef();
	
	const [variableList, setVariableList] = useState(null);
	const [boolShowFilter, setBoolShowFilter] = useState(true);
	
	useEffect(()=>{
		var devData = {};
		var content = [];
		var timeUnix = [];
		var paquets = [];
		var variablesList = [];
        // scan all selected devices tables
		for (let i=0; i<selectedDevices.length; i++){
			scanTable("eEner_".concat(selectedDevices[i])).then(res => {
				if (res.exist){
					// rename variables and devices while ordering data
					content = res.items;
					for (var j in content){
						timeUnix[j] = content[j].Timestamp;
						paquets[j] = content[j].Pack;
						// rename variables
						var variables = Object.keys(content[j]);
						var params = Object.keys(paramNames);
						for (var k in variables){
							if (params.includes(variables[k])){
								content[j][paramNames[variables[k]]] = content[j][variables[k]];
								delete content[j][variables[k]];
								// limit distance values
								if (paramNames[variables[k]] == 'Distance' && parseFloat(content[j][paramNames[variables[k]]]) > 1900){
									content[j][paramNames[variables[k]]] = 0;
								}
							}
							// save non repeated variables in list
							if (!variablesList.includes(paramNames[variables[k]])){
								variablesList.push(paramNames[variables[k]]);
							}
						}
					}
					// order data
					var Sortout = Sort(timeUnix, paquets, content);
					// rename devices while saving ordered data
					var devices = Object.keys(devNames);
					if (devices.includes(selectedDevices[i])){
						devData[devNames[selectedDevices[i]]] = Sortout.data;
					}
					else {
						devData[selectedDevices[i]] = Sortout.data;
					}
					setAuxState(i);
				}
			});
		}
		setVariableList(variablesList);
		setDevicesData(devData);
    }, []); // Only computed at first iteration

    const clickFilter = () => {
        var start = startDate.current.value.split("-");
		var end = endDate.current.value.split("-");
		start = new Date( start[0], start[1] - 1, start[2]);
		end = new Date( end[0], end[1] - 1, end[2]);
		setStartTime(start.getTime()/1000);
		setEndTime(end.getTime()/1000);
    }
	
	const BackFunc = () => {
        setIsChoiceSubmitted(false);
    }
	
	const ToggleFilter = () => {
        setBoolShowFilter(!boolShowFilter);
    }

    return ( 
        <Section>		
            <SectionHeader>
					<li>
						<MarginHeader>
							{"All data"}
							<Button onClick={ToggleFilter} className="generalbutton" > Toggle  </Button>
						</MarginHeader>
					</li>
					{boolShowFilter ? (
						<>
							<li>
								<MarginHeader>
									{"Start date: "}
									<input type="date" ref={startDate} defaultValue={unixDate(startTime)}/>
									{" End date: "}
									<input type="date" ref={endDate} defaultValue={unixDate(endTime)}/>
									<Button onClick={clickFilter} className="generalbutton"> Apply </Button>
								</MarginHeader>
							</li>
							<li>
								<ButtonDownloadPDF variable={"All"}/>
								<ButtonDownloadExcel variable={"All"} dataFiltered={devicesData} /> 
								<Button onClick={() => {BackFunc()}} className="generalbuttonicon" >
									<FileIcon>
										<img src={"/imgs/Home.svg"} width="25px" alt="Home"></img>
									</FileIcon>
								</Button>
							</li>
						</>
					) : (
						<li>
							<Button onClick={() => {BackFunc()}} className="generalbuttonicon" >
								<FileIcon>
									<img src={"/imgs/Home.svg"} width="25px" alt="Home"></img>
								</FileIcon>
							</Button>
						</li>
					)}
			</SectionHeader>
			
			<BlankSpace>
			</BlankSpace>
			
			{variableList && startTime && endTime && boolShowFilter ? (
				<div id={"All"}>

					{variableList.includes("Current") ? ( <GraphSection variable={"Current"} startTime={startTime} endTime={endTime} devicesData={devicesData} /> ) : (null)}
					{variableList.includes("Hourly Energy") ? ( <GraphSection variable={"Hourly Energy"} startTime={startTime} endTime={endTime} devicesData={devicesData} /> ) : (null)}
					{variableList.includes("Battery") ? ( <GraphSection variable={"Battery"} startTime={startTime} endTime={endTime} devicesData={devicesData} /> ) : (null)}

				</div>
			) : (null)}

        </Section>
     );
}
 
export default Content;
