import React from 'react'
import styled from '@emotion/styled'

import InstContainer from './data_blocks/instantaneous' 

import ConfigJson from './../../utils/loadjson';

 
function unixTime(unixtime) {

    var u = new Date(unixtime*1000);

      return u.getFullYear() +
        '-' + ('0' + (u.getMonth()+1)).slice(-2) +
        '-' + ('0' + u.getDate()).slice(-2) + 
        ' ' + ('0' + u.getHours()).slice(-2) +
        ':' + ('0' + u.getMinutes()).slice(-2) +
        ':' + ('0' + u.getSeconds()).slice(-2)
};
 
 
const InstContainers = (props) => {
    var data = props.data;
	var device = props.device;
	
	var time_d = unixTime(data[device]['Timestamp_d']);
	var time_h = unixTime(data[device]['Timestamp_h']);
	

    if (device) {
        
        // CSS
        const ParentContainer = styled.div`
            margin: 0 auto;
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 0px;
            position: relative;
            width: 100%;

            display: flex;
            justify-content: space-around;
            color: black;
            font-size: 16px;
            list-style: none;

            z-index: 1;
            line-height: 11pt;
        `

        return (
            <ParentContainer>
				
                <li><InstContainer variable={'Distance'} value={data[device]['Distance']} timestamp={time_d} configuration={ConfigJson['Distance']} >  </InstContainer></li>
                <li><InstContainer variable={'Altitude'} value={data[device]['Altitude']} timestamp={time_h} configuration={ConfigJson['Altitude']} >  </InstContainer></li>
				
            </ParentContainer>
        ); 
    }
    else {
        return null;
    }

}
 
export default InstContainers;