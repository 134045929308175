import React from 'react';
import styled from '@emotion/styled'
import moment from 'moment';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

//import
import { Preview, print } from 'react-html2pdf';


const ButtonDownloadPDF = (props) => {
     
    var unix_time = (new Date().getTime());
    var readeable_time = (moment(unix_time).format("DD-MM-YYYY HH:mm:ss"));

    const DownloadPDF = () => {

        const input = document.getElementById(props.variable);

        html2canvas(input, {
            scale: 2, // in order to have a better quality, yet waiting time increases
            scrollY: -window.scrollY, // if not, images got cut
        })
          .then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF();

              // get width and height
              const imgProps= pdf.getImageProperties(imgData);
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

              const pageHeight = 295; // Desired document height

              var heightLeft = pdfHeight; // count the height left to print

              pdf.addImage(imgData, 'PDF', 0, 1, pdfWidth-1, pdfHeight); // add first image

              heightLeft -= pageHeight; // decrease height accordingly

              while (heightLeft >= 0) { // if there's still canvas to save
                var position = heightLeft - pdfHeight;
                pdf.addPage(); // add a pdf page
                pdf.addImage(imgData, 'PNG', 0, 1+position, pdfWidth-1, pdfHeight);
                heightLeft -= pageHeight; // decrease height accordingly
              }

              pdf.save(props.variable.concat(" - ").concat(readeable_time).concat(".pdf"));

          });
    }


    const Button = styled.div ` 
        background-color:#ffffff;
    `

    const FileIcon = styled.div`
    text-anchor: middle;
    line-height: 0px;       
    margin-top: 2pt;
    margin-bottom: 1pt
    `

    return ( 
        <Button onClick={DownloadPDF} className="generalbuttonicon">
            <FileIcon>
                <img src={"/imgs/pdf-icon.svg"} width="25px" alt="pdf"></img>
            </FileIcon>
        </Button>
     );
}
 
export default ButtonDownloadPDF;