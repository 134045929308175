import React from 'react'
import styled from '@emotion/styled'


const AverageContainer = (props) => {
    var variable = props.variable;
    var value = null;
    const cnf = props.configuration;

    if (typeof(props.value) != 'string') {
        value = props.value.toFixed(3); // we limit the number to only 2 decimal places
        value = parseFloat(value);

        var Color = `rgb(0, 0, 0)`;
        switch (variable) {
            case 'temperature' :
            case 'radiation' :
                if (value > cnf.threshold) {
                    Color = 255 * (1 - (value - cnf.threshold) / (cnf.max - cnf.threshold));
                    Color =  `rgb(255, ${Color}, 0)`
                }
                else if (value <= cnf.threshold) {
                    Color = 190 * (1 - (cnf.threshold - value) / (cnf.threshold - cnf.min));
                    Color =  `rgb(${Color}, 0, 255)`
                }
                break;
            case 'wind' : 
                Color = 255 * (1 - ((value) / (cnf.max - cnf.min)));
                Color =  `rgb(${Color}, ${Color}, 255)`;
                break;
            case 'humidity' :
            case 'moisture' :
                if (value > cnf.threshold) {
                    Color = 255 * (1 - (value - cnf.threshold) / (cnf.max - cnf.threshold));
                    Color =  `rgb(0, ${Color}, 255)`;
                }
                else if (value <= cnf.threshold) {
                    Color = 300 * (1 - (cnf.threshold - value) / (cnf.threshold - cnf.min));
                    Color =  `rgb(${Color}, 255, 0)`;
                }
                break;
            case 'rain' :
                Color = 255 * (1 - ((value) / (cnf.max - cnf.min)));
                Color =  `rgb(${Color}, ${Color}, 255)`;
                break;
            case 'battery' :
            case 'leafwetness' :
                if (value > cnf.threshold) {
                    Color = 255 * (1 - (value - cnf.threshold) / (cnf.max - cnf.threshold));
                    Color =  `rgb(${Color}, 255, 0)`
                }
                else if (value <= cnf.threshold) {
                    Color = 255 * ((cnf.threshold - value) / (cnf.threshold - cnf.min));
                    Color =  `rgb(255, ${Color}, 0)`
                }
                break;
			case 'Temperature' :
				value = props.value.toFixed(1); // we limit the number to only 2 decimal places
				value = parseFloat(value);
                Color = 255 * ((value - cnf.min) / (cnf.max - cnf.min));
                Color =  `rgb(${Color}, ${255-Color}, ${255-Color})`;
                break;
			case 'Acceleration' :
				value = props.value.toFixed(3); // we limit the number to only 2 decimal places
				value = parseFloat(value);
                Color = 255 * ((value - cnf.min) / (cnf.max - cnf.min));
                Color =  `rgb(${Color}, ${255-Color}, ${255-Color})`;
                break;
			case 'Battery' :
				value = props.value;
				value = parseInt(value);
                Color = 255 * ((value - cnf.min) / (cnf.max - cnf.min));
                Color =  `rgb(${255-Color}, ${Color}, 0)`;
                break;
				
			case 'Distance' :
				value = props.value;
				value = parseInt(value);
				if (value > cnf.max) {
					Color =  `rgb(255, 0, 0)`
				}
				else if (value > cnf.threshold) {
                    Color = 255 * ((value - cnf.threshold) / (cnf.max - cnf.threshold));
                    Color =  `rgb(${Color}, ${255-Color}, 0)`
                }
                else if (value <= cnf.threshold) {
                    Color = 255 * ((cnf.threshold - value) / (cnf.threshold - cnf.min));
                    Color =  `rgb(${Color}, ${255-Color}, 0)`
                }
                break;
            default: break;
        };
    } else {
        value = props.value;
    }
    


    const DataContainer = styled.div`
        margin: 0 auto;
        margin-bottom: 5px;

        background-color: white;

        width: 250px;
        height: 240 px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center; 
        border-radius: 0px;
        line-height: 18pt;
		
		padding: 0px 0px 0px 0px;
		border-color: #000000;
		border-width: 1pt;
		border-style: solid;
    `

    const Tittle = styled.div `
        border: 0 auto;
        width: 100%;
        padding: 0.0em 0px 0.0em 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 
        border-radius: 0px; 

        font-weight: 500;
        font-size: 14pt;

        background-color: white; 
    `

    const Data = styled.div `
        border: 0 auto;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 
        border-radius: 0px; 

        font-weight: 500;
        font-size: 22pt;
        padding: 0.25em 0em 0.40em 0em;

        background: ${Color};
    `
 
    const DataErr = styled.div `
        border: 0 auto;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 
        border-radius: 5px; 

        font-weight: 500;
        font-size: 22pt;
        padding: 0.25em 0em 0.40em 0em;

        background-color: white; 
    `
    
    const Timestamp = styled.div `
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 

        font-weight: 400;
        font-size: 10pt;
        padding: 0.2em 0em 0.5em 0em;
        /* margin-top: 0pt; */
        line-height: 5pt;

        background: white;
    `

    
    var title = ("Average " + String(variable)).toUpperCase();
	if (variable == 'Acceleration') title = ("Average impact").toUpperCase();

    return (
        <DataContainer>
            <Tittle> {title} </Tittle>
            {typeof(props.value) != 'string' ? (
                <>
                    <Timestamp> {"\u00AD"} </Timestamp>
                    <Data> {value} {cnf.units} </Data>
                </>
            ) : (
                <DataErr> {value} </DataErr>
            )}
        </DataContainer>
    ); 
}
 
export default AverageContainer;



