import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';

// Dependencies
import ChartSummoner from '../TemperatureGraph/chartSummoner';
import ButtonDownloadExcel from './buttons/ButtonDownloadExcel';
import ButtonDownloadPDF from './buttons/ButtonDownloadPDF';
import DataBlocks from './data_blocks'

function unixDate(unixtime) {

    var u = new Date(unixtime*1000);

      return u.getFullYear() +
        '-' + ('0' + (u.getMonth()+1)).slice(-2) +
        '-' + ('0' + u.getDate()).slice(-2)
};

const Header = (props) => {
    const devicesData = props.devicesData;
	
	const [dataFiltered, setDataFiltered] = useState(null);
	const [boolShowFilter, setBoolShowFilter] = useState(true);
	const [startTime, setStartTime] = useState(props.startTime);
	const [endTime, setEndTime] = useState(props.endTime);
	const startDate = React.useRef();
	const endDate = React.useRef();

    const Header = styled.div ` 
        background-color: #2946D7;

        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
        list-style: none;
        line-height: 20px;

        padding: 0px 10px 3px 10px;
        border-radius: 0pt;
        border-color: #000000;
        border-width: 0pt;
        border-style: solid;
        top: 50%;

        position: sticky;
        margin: 0 auto;
        vertical-align: middle;
        top: 100px;

        z-index: 99;
    `

    const GraphZone = styled.div ` 
        background-color: white;
		padding: 10px 20px 10px 20px;
		border-color: #000000;
		border-width: 1pt;
		border-style: solid;
		margin: 0 auto;
		vertical-align: middle;
        color: black;
        border-radius: 0pt;
		align-items: center;
    `

    const Section = styled.div ` 
        /* background-color: white, */
        margin-bottom: 20pt;
    `


    const MarginHeader = styled.div ` 
        margin-top: 3.7pt;
        color: white;
    `

    const Button = styled.div ` 
        background-color:#ffffff;
    `

    const ToggleFilter = () => {
        setBoolShowFilter(!boolShowFilter);
    }
	
	const clickFilter = () => {
        var start = startDate.current.value.split("-");
		var end = endDate.current.value.split("-");
		start = new Date( start[0], start[1] - 1, start[2]);
		end = new Date( end[0], end[1] - 1, end[2]);
		setStartTime(start.getTime()/1000);
		setEndTime(end.getTime()/1000);
    }
	
	useEffect(()=>{
		var devices = Object.keys(devicesData);
		var filteredData = {};
		for (let i in devices){
			filteredData[devices[i]] = [];
			for (let j=0; j<devicesData[devices[i]].length; j++){
				if (devicesData[devices[i]][j] != undefined){
					if (devicesData[devices[i]][j].Timestamp >= startTime && devicesData[devices[i]][j].Timestamp <= endTime){
						filteredData[devices[i]].push(devicesData[devices[i]][j]);
					}
				}
			}
		}
		setDataFiltered(filteredData);
	}, [startTime,endTime]);
 
    return ( 
        <Section>
            <Header>
                <li> 
                <MarginHeader>
                    {props.variable == 'Acceleration' ? 'Impacts' : props.variable}
                    <Button onClick={ToggleFilter} className="generalbutton" > Toggle  </Button>
                </MarginHeader>
                </li>

                {boolShowFilter ? (
                    <>
                        <li>
                        <MarginHeader>
							{"Start date: "}
                            <input type="date" ref={startDate} defaultValue={unixDate(startTime)}/>
							{" End date: "}
							<input type="date" ref={endDate} defaultValue={unixDate(endTime)}/>
                            <Button onClick={clickFilter} className="generalbutton"> Apply </Button>
                        </MarginHeader>
                        </li>
						
						<li>
                            <ButtonDownloadPDF variable={props.variable} />
                            <ButtonDownloadExcel variable={props.variable} dataFiltered={dataFiltered} />
                        </li>

                    </>
                ) : (null)}
                
            </Header>

            {/* <GraphZone id={props.variable}> */}
                {boolShowFilter && dataFiltered ? ( 
                    <GraphZone id={props.variable}>
                        {<DataBlocks  variable={props.variable}  dataFiltered={dataFiltered} />}
						{<ChartSummoner variable={props.variable}  dataFiltered={dataFiltered} />} 
                    </GraphZone>
                ) : (null)}
            {/* </GraphZone> */}

        </Section>
    );

}
 
export default Header;
