// Sorts the second vector according to he first vector values, from min to max

const Sort = (time, data) => {
    //1) combine the arrays:
    var list = [];
    for (var j = 0; j < time.length; j++) 
        list.push({'data': data[j], 'time': time[j]});

    //2) sort:
    list.sort(function(a, b) {
        return ((a.time < b.time) ? -1 : ((a.time === b.time) ? 0 : 1));
    });

    //3) separate them back out:
    for (var k = 0; k < time.length; k++) {
        time[k] = list[k].time;
        data[k] = list[k].data;
    }

    return {time: time, data: data};
};


export default Sort;
 