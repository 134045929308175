import React from 'react';
import styled from '@emotion/styled'
import XLSX from 'xlsx';
import moment from 'moment';

// import PerformFilter from './../../../utils/filter_and_offset_data.js'


const ButtonDownloadExcel = (props) => {

    const variable = props.variable.toLowerCase();

    function Workbook() {
        if (!(this instanceof Workbook))
            return new Workbook()
    
        this.SheetNames = []
        this.Sheets = {}
    }
    
    const download = (url, name) => {
        let a = document.createElement('a')
        a.href = url
        a.download = name
        a.click()
    
        window.URL.revokeObjectURL(url)
    }
    
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)

        for (let i=0; i !== s.length; ++i)
            view[i] = s.charCodeAt(i) & 0xFF
    
        return buf
    }
    

    const DownloadExcel = () => {
        var unix_time = (new Date().getTime()); 
        var readeable_time = (moment(unix_time).format("DD-MM-YYYY HH:mm:ss"));

        var data_needed2 = [];
        var k = 0;
        var sheets_data = [];

        if (props.wholeData) {
            const wholeData = JSON.parse(props.wholeData); 
            const dt_k = JSON.parse(props.dt_k); 
            const d_k_sub = JSON.parse(props.d_k_sub); 
            var device_list = []; // contains a list of non-repeated devices

            for (var j in dt_k) { // iterate through device types
                for (var i in d_k_sub[j]) { // iterate through devices in each device type
        
                    // check if device exists, in order to avoid repetition
                    var is_repeated = 0;
                    for (var z in device_list){
                        if (d_k_sub[j][i][0] == device_list[z]) { // device already exists
                            is_repeated++;
                        }
                    }
                    
                    if (is_repeated === 0) { // device doesn't exist
                        device_list.push(d_k_sub[j][i]);
                        console.log(wholeData[dt_k[j]]);
                        for (var h in wholeData[dt_k[j]][i][d_k_sub[j][i]]) { // iterate through each row of the given device
                            if (variable === 'all') {
                                data_needed2.push((wholeData[dt_k[j]][i][d_k_sub[j][i]][h]));
                            }
                            else {
                                if (variable === "gps") {
                                    var lat = parseFloat(wholeData[dt_k[j]][i][d_k_sub[j][i]][h]['GPS_latitude']);
                                    var lng = parseFloat(wholeData[dt_k[j]][i][d_k_sub[j][i]][h]['GPS_longitude']);
                                    data_needed2.push({time_unix: parseInt(wholeData[dt_k[j]][i][d_k_sub[j][i]][h].time_unix), time: (wholeData[dt_k[j]][i][d_k_sub[j][i]][h].time), [variable+'_latitude']: lat, [variable+'_longitude']: lng});
                                } else {
                                    var value = parseFloat(wholeData[dt_k[j]][i][d_k_sub[j][i]][h][variable]);
                                    data_needed2.push({time_unix: parseInt(wholeData[dt_k[j]][i][d_k_sub[j][i]][h].time_unix), time: (wholeData[dt_k[j]][i][d_k_sub[j][i]][h].time), [variable]: value});
                                }
                            }
                        }
                        sheets_data[k] = data_needed2;
                        data_needed2 = [];
                        k++;
                    }
                }
            }

            const wb2 = new Workbook();
            for (var device in device_list) {
                const ws2 = XLSX.utils.json_to_sheet(sheets_data[device]);
                wb2.SheetNames.push(device_list[device]);
                wb2.Sheets[device_list[device]] = ws2;
            }

            const wbout2 = XLSX.write(wb2, {bookType:'xlsx', bookSST:true, type: 'binary'});
            let url2 = window.URL.createObjectURL(new Blob([s2ab(wbout2)], {type:'application/octet-stream'}));
            download(url2, props.variable.concat(" - ").concat(readeable_time).concat('.xlsx'));

        }
    }


    const Button = styled.div ` 
        background-color:#ffffff;
    `

    const FileIcon = styled.div`
    text-anchor: middle;
    line-height: 0px;       
    margin-top: 2pt;
    margin-bottom: 1pt
    `

    return ( 
        <Button onClick={DownloadExcel} className="generalbuttonicon">
            {/* Download {props.variable} Excel */}
            <FileIcon>
                    <img src={"/imgs/excel-icon.svg"} width="25px" alt="pdf"></img>
            </FileIcon>
        </Button>
     );
}
 
export default ButtonDownloadExcel;